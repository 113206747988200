import moment, { Moment } from "moment-timezone";
import IAirportConfig from "../../models/airports/IAirportConfig";
import { CommonHelper } from "./CommonHelper";
const format = "YYYY-MM-DDTHH:mm";
const dateFormat = "YYYY-MM-DD";

export default class DateHelper {
  public static isValidDate(dateStr: string) {
    const date = moment(dateStr);
    if (date.isValid()) {
      return true;
    }

    return false;
  }

  public static getCustomDuration(airport: string, duration?: string) {
    let endTime = [];
    let result = "";
    if (duration) {
      endTime = duration?.match(/\d+/gi)?.map(Number) ?? [];
      if (endTime?.length === 1 && endTime[0] > 12) {
        endTime.unshift(0);
      } else if (endTime.length === 1) {
        endTime.push(0);
      }
      result = `${this.getTime(airport)}|${this.getTime(
        airport,
        endTime,
        true
      )}`;
    }
    return result;
  }

  public static getTime(airport: string, arr?: any, isAddHours = false) {
    const date = DateHelper.getCurrentDateTimeForAirport(airport);
    if (arr?.length > 0) {
      date.hours(isAddHours ? date.hours() + arr[0] : arr[0]);
      date.minutes(isAddHours ? date.minutes() + arr[1] : arr[1]);
    }
    return date.format(format);
  }

  public static getFormattedTime(time?: Date) {
    return time ? moment(time).format(format) : "";
  }

  public static getFormattedMomentTime(time?: Moment) {
    return time ? time.format(format) : "";
  }

  public static changeDateFromDateTime(source: Date, destination: Date) {
    const day = destination.getDate();
    const month = destination.getMonth();
    const year = destination.getFullYear();

    const hour = source.getHours();
    const minutes = source.getMinutes();
    const seconds = source.getSeconds();

    return new Date(year, month, day, hour, minutes, seconds);
  }

  public static changeTimeFromDateTime(source: Date, destination: Date) {
    const day = source.getDate();
    const month = source.getMonth();
    const year = source.getFullYear();

    const hour = destination.getHours();
    const minutes = destination.getMinutes();
    const seconds = destination.getSeconds();

    return new Date(year, month, day, hour, minutes, seconds);
  }

  public static getDayFromDate(dateISOString: string) {
    return moment(dateISOString).format("dddd");
  }

  public static getTimePickerValue(
    startDate: Date,
    endDate: Date,
    airport: string
  ) {
    // Calculate the difference in milliseconds
    const differenceMs: number = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to hours
    const differenceHours = differenceMs / (1000 * 60 * 60);

    const currentDate12AMDate = new Date(
      this.getDefaultDateStrForAirport(airport)
    );
    currentDate12AMDate.setHours(0, 0, 0, 0);

    return moment(currentDate12AMDate).add(differenceHours, "hours").toDate();
  }

  public static getFormattedDateTime(date: Date, format: string) {
    return moment(date).format(format);
  }

  public static timeToMinute(time: any) {
    let rMinutes = 1439; //23:59 Hr=1439 minutes
    time = time.split(":");
    if (time.length < 2) {
      throw new Error("Invalid time");
    }
    const hours = parseInt(time[0]) * 60;
    const minutes = parseInt(time[1]);
    rMinutes = hours + minutes;

    return rMinutes > 1439 ? 1439 : rMinutes;
  }

  public static minuteToTime(value: number) {
    value = value > 1439 ? 1439 : value;
    const hours: number = Math.floor(value / 60);
    const minutes: number = value - hours * 60;
    const time = { hours: hours.toString(), minutes: minutes.toString() };
    if (time.hours.length === 1) {
      time.hours = "0" + hours;
    }
    if (time.minutes.length === 1) {
      time.minutes = "0" + minutes;
    }
    if (minutes === 0) {
      time.minutes = "00";
    }

    return `${time.hours}:${time.minutes}`;
  }

  public static getNearest30Minutes(airport: string) {
    const now = DateHelper.getCurrentDateTimeForAirport(airport);
    const minutes = now.minutes();
    const minutesToNext30 = 30 - (minutes % 30);
    now.minutes(minutes + minutesToNext30);
    now.seconds(0);
    return now;
  }

  public static getDateTimeFromHHMMFormat(hhmmFormat: string) {
    const startDate = moment(new Date()).startOf("day"); // 12 AM date
    return startDate.add(moment.duration(hhmmFormat)).toDate();
  }

  public static getDefaultDateStrForAirport(airportCode: string): string {
    return this.getDefaultDateForAirport(airportCode).format(dateFormat);
  }

  public static getDefaultDateForAirport(airportCode: string): Moment {
    const config: IAirportConfig = CommonHelper.getAirportConfig(airportCode);
    if (config.hardCodedDates && config.defaultDate) {
      return moment.tz(config.defaultDate, config.timeZone);
    } else {
      return moment.tz(config.timeZone);
    }
  }

  public static getCurrentDateTimeStrForAirport(airportCode: string): string {
    return this.getCurrentDateTimeForAirport(airportCode).format(format);
  }

  public static getCurrentDateTimeForAirport(airportCode: string): Moment {
    const config: IAirportConfig = CommonHelper.getAirportConfig(airportCode);
    if (config.hardCodedDates && config.defaultStart) {
      return moment.tz(config.defaultStart, config.timeZone);
    } else {
      return moment.tz(config.timeZone);
    }
  }

  public static getDefaultDateTimeStrForAirport(airportCode: string): string {
    return this.getDefaultDateTimeForAirport(airportCode).format(format);
  }

  public static getDefaultDateTimeForAirport(airportCode: string): Moment {
    const config: IAirportConfig = CommonHelper.getAirportConfig(airportCode);
    if (config.hardCodedDates && config.defaultStart) {
      return moment.tz(config.defaultStart, config.timeZone);
    } else {
      return moment
        .tz(config.timeZone)
        .startOf("day")
        .add(moment.duration(10, "hours"));
    }
  }

  public static formatDateTime(dateTime: Moment, format: string) {
    return dateTime.format(format);
  }
}
