import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { initializeIcons } from "@fluentui/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./components/App";
import { msalConfig } from "./components/common/auth/authConfig";
import { NotificationProvider } from "./context/NotificationContext";
import "./scss/styles.scss";
import Store from "./store/Store";

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <FluentProvider
    theme={webLightTheme}
    style={{ backgroundColor: "#F2F2F2", height: "100%" }}
  >
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={Store}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  </FluentProvider>,
  document.getElementById("root")
);
