import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { Button } from "@fluentui/react-button";
import React from "react";
import { getButtonStyles } from "./AmdButtonStyles";
import { AmdButtonType } from "./AmdButtonType";

export interface AmdButtonProps {
  key?: string;
  type: string;
  onClick?: any;
  text?: string;
  children?: React.ReactElement | string;
  className?: string;
  shape?: string;
  testId?: string;
  id?: string;
  disabled?: boolean;
  imageIcon?: any;
  prefixImageIconBeforeText?: boolean;
}

export const AmdButton: React.FunctionComponent<AmdButtonProps> = (
  props: AmdButtonProps
) => {
  const buttonStyles: any = getButtonStyles(props.type);
  if (props.type === AmdButtonType.ICON_BUTTON) {
    return (
      <button
        className={`amd-btn-icon ${props.className}`}
        onClick={props.onClick}
        data-testid={props.testId}
        id={props.id}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    );
  } else if (props.type === AmdButtonType.CHIP) {
    return (
      <button
        data-testid={props.testId}
        style={{ zIndex: 2 }}
        className={`${buttonStyles.root} ${props.className}`}
        onClick={props.onClick}
        id={props.id}
        disabled={props.disabled}
      >
        {props.text ? props.text : props.children}
      </button>
    );
  } else if (props.type === AmdButtonType.DASHED) {
    return (
      <DefaultButton
        data-testid={props.testId}
        onClick={props.onClick}
        className={`${props.className} ${buttonStyles.root}`}
      >
        {props.prefixImageIconBeforeText && props.imageIcon}
        {props.text && <>{props.text}</>}
        {props.children && <>{props.children}</>}
        {!props.prefixImageIconBeforeText && props.imageIcon}
      </DefaultButton>
    );
  } else if (props.type === AmdButtonType.PRIMARY) {
    return (
      <PrimaryButton
        data-testid={props.testId}
        onClick={props.onClick}
        className={`${props.className} ${buttonStyles.root}`}
      >
        {props.prefixImageIconBeforeText && props.imageIcon}
        {props.text && <>{props.text}</>}
        {props.children && <>{props.children}</>}
        {!props.prefixImageIconBeforeText && props.imageIcon}
      </PrimaryButton>
    );
  }

  return (
    <>
      <Button
        data-testid={props.testId}
        key={props.key}
        onClick={props.onClick}
        className={`${buttonStyles.root} ${props.className}`}
        id={props.id}
        disabled={props.disabled}
      >
        {props.prefixImageIconBeforeText && props.imageIcon}
        {props.text && <>{props.text}</>}
        {props.children && <>{props.children}</>}
        {!props.prefixImageIconBeforeText && props.imageIcon}
      </Button>
    </>
  );
};

AmdButton.defaultProps = {
  className: "",
  type: AmdButtonType.PRIMARY,
  id: "",
};
