import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdClock: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="clock-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.25C12.2812 0.25 15.75 3.71875 15.75 8C15.75 12.2812 12.2812 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25ZM8 14.25C11.4375 14.25 14.25 11.4688 14.25 8C14.25 4.5625 11.4375 1.75 8 1.75C4.53125 1.75 1.75 4.5625 1.75 8C1.75 11.4688 4.53125 14.25 8 14.25ZM9.90625 11L7.25 9.0625C7.15625 9 7.125 8.875 7.125 8.78125V3.625C7.125 3.4375 7.28125 3.25 7.5 3.25H8.5C8.6875 3.25 8.875 3.4375 8.875 3.625V8.0625L10.9375 9.59375C11.125 9.71875 11.1562 9.9375 11.0312 10.125L10.4375 10.9062C10.3125 11.0938 10.0938 11.125 9.90625 11Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdClock.defaultProps = {
  color: "#666666",
};
