import * as React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { RESOURCE_TAB_CAROUSEL_INDEX_KEY } from "../../../utils/resource/ResourceConstants";
import { AmdButton } from "../form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../form-controls/uncontrolled/Button/AmdButtonType";
import { AmdChevron } from "../icons/chevron/chevron";
import { ChevronDirection } from "../icons/chevron/chevronDirection";
import { NoDataCard } from "../no-data-card/NoDataCard";
import { getCarouselStyles } from "./CarouselStyles";

export interface ICustomCarouselProps {
  carouselItems: any[];
  emptyStateMessage: string;
}

export const Carousel: React.FunctionComponent<ICustomCarouselProps> = ({
  carouselItems,
  emptyStateMessage,
}) => {
  const [currentItem, setCurrentItem] = React.useState(0);
  const [carouselEls, setCarouselRefs] = React.useState<any[]>([]);

  React.useEffect(() => {
    setCarouselRefs(carouselItems.map((_x) => React.createRef()));
  }, [carouselItems]);

  const carouselStyles = getCarouselStyles();

  const handleCarouselNavigation = (counter = 1) => {
    const currentCounter = CommonHelper.getCarouselNavCurrentCounter(
      counter,
      currentItem,
      carouselItems.length
    );
    window.sessionStorage.setItem(
      RESOURCE_TAB_CAROUSEL_INDEX_KEY,
      carouselItems[currentCounter]?.key?.toString()
    );
    setCurrentItem(currentCounter);
  };

  return carouselItems && carouselItems.length > 0 ? (
    <div className={carouselStyles.carousel} data-testid="customCarousel">
      <div className={carouselStyles.carouselBody}>
        {carouselItems.map((carouselItem: any, i: number) => {
          return (
            <div
              key={carouselItem.key}
              className={`${
                i === currentItem ? carouselStyles.carouselItemActive : ""
              } ${carouselStyles.carouselItem}`}
              ref={carouselEls[i]}
            >
              {carouselItem.content}
            </div>
          );
        })}
      </div>
      {carouselItems.length > 0 && (
        <div className={carouselStyles.carouselControls}>
          <AmdButton
            type={AmdButtonType.ICON_BUTTON}
            onClick={() => handleCarouselNavigation(-1)}
          >
            <AmdChevron height="14" direction={ChevronDirection.LEFT} />
          </AmdButton>
          <AmdButton
            type={AmdButtonType.ICON_BUTTON}
            onClick={() => handleCarouselNavigation(1)}
          >
            <AmdChevron height="14" direction={ChevronDirection.RIGHT} />
          </AmdButton>
        </div>
      )}
    </div>
  ) : (
    <NoDataCard message={emptyStateMessage} />
  );
};

Carousel.defaultProps = {
  carouselItems: [],
};
