import React from 'react';
import { IAmdIconProps } from '../IAmdIconProps';
import { DismissType } from './dismissType';

export const AmdDismiss: React.FunctionComponent<IAmdIconProps> = (props: IAmdIconProps) => {
    return (
        <>
            {props.type === DismissType.CIRCLE && <svg data-testid={props.dataTestId}
            className={props.className}
            width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0.5C15.5228 0.5 20 4.97715 20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5ZM10 2C5.30558 2 1.5 5.80558 1.5 10.5C1.5 15.1944 5.30558 19 10 19C14.6944 19 18.5 15.1944 18.5 10.5C18.5 5.80558 14.6944 2 10 2ZM13.4462 6.89705L13.5303 6.96967C13.7966 7.23594 13.8208 7.6526 13.6029 7.94621L13.5303 8.03033L11.061 10.5L13.5303 12.9697C13.7966 13.2359 13.8208 13.6526 13.6029 13.9462L13.5303 14.0303C13.2641 14.2966 12.8474 14.3208 12.5538 14.1029L12.4697 14.0303L10 11.561L7.53033 14.0303C7.26406 14.2966 6.8474 14.3208 6.55379 14.1029L6.46967 14.0303C6.2034 13.7641 6.1792 13.3474 6.39705 13.0538L6.46967 12.9697L8.939 10.5L6.46967 8.03033C6.2034 7.76406 6.1792 7.3474 6.39705 7.05379L6.46967 6.96967C6.73594 6.7034 7.1526 6.6792 7.44621 6.89705L7.53033 6.96967L10 9.439L12.4697 6.96967C12.7359 6.7034 13.1526 6.6792 13.4462 6.89705Z" fill="#333333"/>
            </svg>}
            {props.type === DismissType.DEFAULT && <svg data-testid={props.dataTestId}
            className={props.className}
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.209704 0.387101L0.292893 0.292893C0.653377 -0.0675905 1.22061 -0.0953204 1.6129 0.209704L1.70711 0.292893L8 6.585L14.2929 0.292893C14.6834 -0.0976309 15.3166 -0.0976309 15.7071 0.292893C16.0976 0.683418 16.0976 1.31658 15.7071 1.70711L9.415 8L15.7071 14.2929C16.0676 14.6534 16.0953 15.2206 15.7903 15.6129L15.7071 15.7071C15.3466 16.0676 14.7794 16.0953 14.3871 15.7903L14.2929 15.7071L8 9.415L1.70711 15.7071C1.31658 16.0976 0.683418 16.0976 0.292893 15.7071C-0.0976309 15.3166 -0.0976309 14.6834 0.292893 14.2929L6.585 8L0.292893 1.70711C-0.0675905 1.34662 -0.0953204 0.779392 0.209704 0.387101L0.292893 0.292893L0.209704 0.387101Z" fill="#1A1A1A"/>
            </svg>}
        </>
    )
}

AmdDismiss.defaultProps = {
    type: DismissType.DEFAULT,
    className: ''
}