import { PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PageEnum from "../../../enum/PageEnum";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { airportCodeActions } from "../../../store/airport/AirportCodeSlice";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { AdminContainer } from "../../admin/AdminContainer";
import { AppSettings } from "../../app-settings/AppSettings";
import { Baggage } from "../../baggage/Baggage";
import { CrisisDashboard } from "../../events/crisis-dashboard/CrisisDashboard";
import { EventContainer } from "../../events/event-container/EventContainer";
import { EventTasks } from "../../events/event-container/event-item/event-tasks/EventTasks";
import { FlightsSchedule } from "../../flights/flights-schedule/FlightsSchedule";
import { Home } from "../../home/Home";
import { MultiAirport } from "../../multi-airport/MultiAirport";
import { NotificationsContainer } from "../../notifications/NotificationsContainer";
import { PAX } from "../../pax/PAX";
import { PAXTouchPointsContainer } from "../../pax/touchpoints/PAXTouchPointsComponent";
import { ResourceAllocation } from "../../resources-allocation/ResourceAllocation";
import { Weather } from "../../weather/Weather";
import { AccessDenied } from "../access-denied/AccessDenied";
import { LoadingScreen } from "../loading-screen/LoadingScreen";
import NavBar from "../nav-bar/NavBar";
import { SignIn } from "../sign-in/SignIn";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout: React.FunctionComponent = () => {
  let isAuthenticated = useIsAuthenticated(); // Used only for Web App, not for Teams.
  const [userTenantId, setUserTenantId] = React.useState<string | undefined>(
    ""
  );
  const allowedTenants = process.env.REACT_APP_ALLOWED_TENANTIDS
    ? process.env.REACT_APP_ALLOWED_TENANTIDS.toString().split(";")
    : [];

  microsoftTeams.initialize();
  const { accounts } = useMsal();

  const isInTeamsContext = CommonHelper.isInTeams();
  if (userTenantId === "") {
    if (isInTeamsContext) {
      microsoftTeams.getContext((context) => {
        setUserTenantId(context.tid);
      });
    } else if (accounts.length > 0) {
      setUserTenantId(accounts[0].tenantId);
      isAuthenticated = true;
    }
  }

  const isValidTenantId = CommonHelper.isValidTenant(
    userTenantId,
    allowedTenants
  );

  // Fetching airport code
  const msalInstance = useMsal().instance as PublicClientApplication;
  const dispatch = useAppDispatch();
  useEffect(() => {
    CommonHelper.getAirportCode(msalInstance).then((airportCode: string) => {
      dispatch(airportCodeActions.setAirportCode({ airportCode }));
    });
  }, []);

  const airport = useAppSelector((state) => getAirportCode(state));

  if (!airport) {
    return <LoadingScreen message="Please wait.." />;
  }

  return (
    <>
      {!isAuthenticated && !isInTeamsContext && <SignIn />}
      {(isAuthenticated || isInTeamsContext) && (
        <BrowserRouter>
          {!isInTeamsContext ? <NavBar /> : <></>}
          <div
            className={`amd-maincontainer ${CommonHelper.getLayoutClass(
              isInTeamsContext
            )}`}
          >
            <main className="amd-main">
              {isValidTenantId && (
                <Switch>
                  <Route exact path="/">
                    <Redirect to={`/${PageEnum.PageUrl.Home}`} />
                  </Route>
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Home}`}
                    component={Home}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Flights360}`}
                    component={FlightsSchedule}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Baggage}`}
                    component={Baggage}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Passenger}`}
                    component={PAX}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Weather}`}
                    component={Weather}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.EventManagement}`}
                    component={EventContainer}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Notifications}`}
                    component={NotificationsContainer}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.MultiAirports}`}
                    component={MultiAirport}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.ResourceAllocation}`}
                    component={ResourceAllocation}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.Admin}`}
                    component={AdminContainer}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.ApplicationSettings}`}
                    component={AppSettings}
                  />
                  <Route
                    exact
                    path={`/${PageEnum.PageUrl.EventManagement}`}
                    component={EventContainer}
                  />
                  <Route exact path="/event-tasks" component={EventTasks} />
                  <Route
                    exact
                    path="/crisis-dashboard"
                    component={CrisisDashboard}
                  />
                  <Route
                    exact
                    path="/pax-poc"
                    component={PAXTouchPointsContainer}
                  />
                </Switch>
              )}
              {!isValidTenantId && userTenantId && <AccessDenied />}
              {!isValidTenantId && !userTenantId && (
                <LoadingScreen message="Please wait.." />
              )}
            </main>
          </div>
        </BrowserRouter>
      )}
    </>
  );
};
