import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import HOPEnum from "../../enum/HOPEnum";
import { IHOPSummaryItem } from "../../models/health-of-operations/IHOPSummary";
import IAirportSummary from "../../models/multi-airports/IAirportSummary";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { AmdRadarChart } from "../common/charts/RadarChart/RadarChart";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdGreen } from "../common/icons/green/Green";
import { AmdPin } from "../common/icons/pin/pin";
import { AmdShowGraph } from "../common/icons/show-graph/ShowGraph";
import { AmdUnPin } from "../common/icons/unpin/unpin";
import { AmdProgressIndicator } from "../common/progress-indicator/ProgressIndicator";
import { AirportOTPSection } from "./airportOTPSection";
import { AirportStatusSummary } from "./AirportStatusSummary";

export const AirportContainer: React.FunctionComponent<IAirportSummary> = (
  props: IAirportSummary
) => {
  const [showGraph, setShowGraph] = useState(false);
  const [otpData, setOtpData] = useState<IHOPSummaryItem>();
  const [summaryAirportData, setSummaryAirportData] =
    useState<IHOPSummaryItem[]>();
  const [hopChartData, setHopChartData] = useState<any>();

  const selectedTime = {
    start: HOPHelper.getCurrentTime(props.code),
    end: "23:30",
  };
  useEffect(() => {
    const otpData = props.dimensions.current.filter(
      (item) => item.label === "OTP"
    )[0];
    const summaryAirportData = props.dimensions.current.filter(
      (item) => item.label !== "OTP"
    );
    setOtpData(otpData);
    setSummaryAirportData(summaryAirportData);

    const hopData = HOPHelper.getHOPData(props.dimensions);
    const chartData = HOPHelper.getRadarChartData(
      hopData,
      selectedTime,
      props.code
    );
    setHopChartData(chartData);
  }, [props.dimensions]);

  const onShowGraphClick = () => {
    setShowGraph(!showGraph);
  };
  const onPinClick = () => {
    props.pinnedValue(props.id);
  };

  return (
    <>
      <section
        className="amd-section-shadow mb-3"
        data-testid="airport-container"
      >
        <div className=" amd-container-hspacebtwn mb-2">
          <a
            className={`d-flex amd-container-vcenter w-50 ${contentStyles.airportFont}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.localStorage.setItem("apocSelectedAirportId", props.id);
            }}
          >
            {props.code} - {props.name}
          </a>
          <div className="d-flex ">
            <div className="amd-container-vcenter">
              <AmdButton type={AmdButtonType.ICON_BUTTON}>
                {props.isPinned ? (
                  <AmdPin className={`amd-container-vcenter mt-1`} />
                ) : (
                  <AmdUnPin
                    className={`amd-container-vcenter ${contentStyles.marginLogo}`}
                  />
                )}
              </AmdButton>
              <span
                className="fw-400 fs-14 fc--primary cursor-pointer"
                onClick={() => {
                  onPinClick();
                }}
                onKeyDown={() => {
                  onPinClick();
                }}
              >
                {!props.isPinned ? "Pin" : "Unpin"}
              </span>
            </div>
            <div className="amd-container-vcenter">
              <AmdButton type={AmdButtonType.ICON_BUTTON} className="ml-3">
                <AmdShowGraph className="amd-container-vcenter mt-1" />
              </AmdButton>
              <span
                className="fw-400 fs-14 fc--primary cursor-pointer"
                onClick={onShowGraphClick}
                onKeyDown={onShowGraphClick}
              >
                Show Graph
              </span>
            </div>
          </div>
          <div className="d-flex amd-container-vcenter">
            <AirportOTPSection otpData={otpData} />
          </div>
        </div>
        <AirportStatusSummary key={props.id} summaryData={summaryAirportData} />

        {showGraph && (
          <>
            <div className="mt-3 ml-1">
              <div className="d-flex amd-container-vcenter w-50">
                <AmdGreen />
                <span className="mx-1 fw-600">Confidence Factor</span>
                <AmdProgressIndicator
                  value={0.85}
                  size="large"
                  shape="rounded"
                  color={HOPHelper.getConfidenceFactorColor(
                    HOPEnum.HOPDataStatus.Good
                  )}
                />
                <span className="ml-1 fw-600">85%</span>
              </div>
            </div>
            <div className="mt-1">
              <section className="mt-sm-4 mb-sm-4 ml-2">
                <div className="d-flex amd-container-haround">
                  <div>
                    <AmdRadarChart chartData={hopChartData} />
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </section>
    </>
  );
};

const contentStyles = mergeStyleSets({
  airportFont: {
    font: "Sorce Sans Pro",
    fontSize: "18px",
    color: "#005eb8",
    fontWeight: "600",
  },
  marginLogo: {
    marginTop: "3px",
  },
});

export interface IAirportData {
  airportData: IAirportSummary[];
}
