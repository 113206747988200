import {
  getTheme,
  IDropdownOption,
  IIconProps,
  ITag,
  mergeStyleSets,
  Modal,
} from "@fluentui/react";
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import React, { useRef, useState } from "react";
import QueryCondition from "../query-type-filter/QueryCondition";

interface IGlobalFilterPopUpProps {
  hideDialog: boolean;
  onModalPopupClose: () => void;
}

const GlobalFilterPopUp: React.FC<IGlobalFilterPopUpProps> = (
  props: IGlobalFilterPopUpProps
) => {
  const [selectedOperator, setSelectedOperator] = useState<IDropdownOption>();
  const [filteredAirlines, setFilteredAirlines] = useState<ITag[]>();

  const selectedAirlines = useRef<ITag[]>([]);

  const setSelectedAirlines = (airlines: ITag[]): void => {
    selectedAirlines.current = airlines;
  };

  const onChangeOperator = (item?: IDropdownOption): void => {
    setFilteredAirlines([]);
    setSelectedOperator(item);
  };

  const onApplyFilter = (): void => {
    props.onModalPopupClose();
    setFilteredAirlines(selectedAirlines.current);
  };

  return (
    <Modal
      titleAriaId="Global Filter Popup"
      isOpen={!props.hideDialog}
      onDismiss={props.onModalPopupClose}
      isBlocking={true}
      containerClassName={`${contentStyles.model} w-70`}
      overlay={{ styles: { root: contentStyles.backdrop } }}
    >
      <header className={`${contentStyles.header} amd-container-vcenter`}>
        <h2 className={`${contentStyles.heading} fs-24 fw-500`}>
          Global Filters
        </h2>
        <IconButton
          data-testid="dismiss-button"
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.onModalPopupClose}
        />
      </header>

      <section className={`${contentStyles.queryBody} amd-container-vcenter`}>
        <p className={`${contentStyles.where} fs-16 fw-500`}>WHERE</p>
        <QueryCondition
          column="Airlines"
          operator={selectedOperator}
          setOperator={onChangeOperator}
          airlines={filteredAirlines}
          setSelectedAirlines={setSelectedAirlines}
        />
      </section>

      <footer
        className={`${contentStyles.footer} amd-container-vcenter jc-spacebtwn`}
      >
        <DefaultButton
          className={contentStyles.filterButtons}
          text="Cancel"
          ariaLabel="Cancel"
          onClick={props.onModalPopupClose}
        />
        <PrimaryButton
          text="Apply filter"
          ariaLabel="Apply filter"
          onClick={onApplyFilter}
        />
      </footer>
    </Modal>
  );
};

export default GlobalFilterPopUp;

const cancelIcon: IIconProps = { iconName: "Cancel" };
const theme = getTheme();

const contentStyles = mergeStyleSets({
  model: {
    paddingTop: "4px",
    borderRadius: "4px",
    maxWidth: "1000px",
    position: "absolute",
    top: "15%",
    boxShadow: "4px 10px 12px 0px rgba(0, 0, 0, 0.25)",
  },
  header: {
    flex: "1 1 auto",
    padding: "0px 12px",
  },
  heading: {
    paddingLeft: "12px",
  },
  where: {
    color: "#808080",
    padding: "6px 12px",
  },
  queryBody: {
    padding: "10px 24px",
    borderBottom: "1px solid #CCCCCC",
    borderTop: "1px solid #CCCCCC",
  },
  footer: {
    padding: "18px 24px",
  },
  filterButtons: {
    color: "#0C66E1",
    borderColor: "#0C66E1",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    alignSelf: "flex-start",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
