import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdDots: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="dots-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 4C1.53125 4 2 4.46875 2 5C2 5.5625 1.53125 6 1 6C0.4375 6 0 5.5625 0 5C0 4.46875 0.4375 4 1 4ZM0 1.25C0 0.71875 0.4375 0.25 1 0.25C1.53125 0.25 2 0.71875 2 1.25C2 1.8125 1.53125 2.25 1 2.25C0.4375 2.25 0 1.8125 0 1.25ZM0 8.75C0 8.21875 0.4375 7.75 1 7.75C1.53125 7.75 2 8.21875 2 8.75C2 9.3125 1.53125 9.75 1 9.75C0.4375 9.75 0 9.3125 0 8.75Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdDots.defaultProps = {
  color: "#005EB8",
};
