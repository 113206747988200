import React, { createContext, useMemo, useState } from "react";

interface INotification {
  type: string;
  message: string;
}

interface INotificationContextProps {
  notifications: INotification[];
  addNotification: (type: string, message: string) => void;
  removeNotification: (index: number) => void;
  clearNotifications: () => void;
}

export const NotificationContext = createContext<INotificationContextProps>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
  clearNotifications: () => {},
});

export const NotificationProvider: React.FunctionComponent = ({ children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const addNotification = (type: string, message: string) => {
    setNotifications([...notifications, { type, message }]);
    window.scroll(0, 0);

    setTimeout(() => setNotifications([]), 5000); // 5000 milliseconds = 5 seconds
  };

  const removeNotification = (index: number) => {
    setNotifications([
      ...notifications.slice(0, index),
      ...notifications.slice(index + 1),
    ]);
    window.scroll(0, 0);
  };

  const clearNotifications = () => {
    setNotifications([]);
    window.scroll(0, 0);
  };

  const notificationContextValue = useMemo(() => {
    return {
      notifications,
      addNotification,
      removeNotification,
      clearNotifications,
    };
  }, [notifications, addNotification, removeNotification, clearNotifications]);

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
