import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Image } from "@fluentui/react-northstar";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  IWeatherSummary,
  IWeatherSummaryTableData,
} from "../../../models/weather/IWeatherSummary";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import {
  WEATHER_SUMMARY_LEFT_TABLE_KEYS,
  WEATHER_SUMMARY_RIGHT_TABLE_KEYS,
} from "../../../utils/weather/WeatherConstants";
import { WeatherHelper } from "../../../utils/weather/WeatherHelper";
import { AmdTable } from "../../common/form-controls/uncontrolled/Table/AmdTable";
import { AmdCloud } from "../../common/icons/cloud/Cloud";
import { AmdOptions } from "../../common/icons/options/Options";
import { AmdMessage } from "../../common/message/Message";

export interface IWeatherSummaryCardProps {
  airport: string;
}

export const WeatherSummaryCard: React.FunctionComponent<
  IWeatherSummaryCardProps
> = ({ airport }) => {
  const [weatherSummary, setWeatherSummary] = useState<IWeatherSummary | null>(
    null
  );
  const [summaryLeftTableData, setSummaryLeftTableData] = useState<
    IWeatherSummaryTableData[]
  >([]);
  const [summaryRightTableData, setSummaryRightTableData] = useState<
    IWeatherSummaryTableData[]
  >([]);
  const msalInstance = useMsal().instance as PublicClientApplication;

  useEffect(() => {
    let isMounted = true;
    if (airport !== "") {
      WeatherHelper.getCurrentWeatherSummary(msalInstance, airport).then(
        (response: any) => {
          if (isMounted && response && response.data) {
            setWeatherSummary(response.data);
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [airport]);

  useEffect(() => {
    if (weatherSummary) {
      const _summaryLeftTableData = WeatherHelper.getSummaryTableData(
        weatherSummary,
        WEATHER_SUMMARY_LEFT_TABLE_KEYS
      );
      setSummaryLeftTableData(_summaryLeftTableData);

      const _summaryRightTableData = WeatherHelper.getSummaryTableData(
        weatherSummary,
        WEATHER_SUMMARY_RIGHT_TABLE_KEYS
      );
      setSummaryRightTableData(_summaryRightTableData);
    }
  }, [weatherSummary]);

  const renderHeader = () => {
    return (
      <div className="amd-container-hspacebtwn pa-sm-2">
        <AmdCloud />
        <span className="fs-14 fw-600 fc--gray800">Weather</span>
        <AmdOptions />
      </div>
    );
  };

  return (
    <>
      <div
        data-testid="weather-summary-card"
        onClick={() => CommonHelper.handleRedirects("WEATHER")}
        className="cursor-pointer"
      >
        <>
          {renderHeader()}
          <div className="pa-sm-2">
            <AmdMessage width={349} className="mt-2">
              <>
                <div className="d-iblock">
                  <label className="d-iblock fw-600">Temperature</label>
                  <span className="d-iblock ml-1 mr-3">
                    {weatherSummary && weatherSummary["temperature"]?.body}{" "}
                    {weatherSummary && weatherSummary["temperature"]?.unit}
                  </span>
                </div>
                <div className="d-iblock fw-600 mr-2">
                  <label className="d-iblock">
                    {weatherSummary &&
                      weatherSummary["temperature"]?.leftFooterBody}
                  </label>
                </div>
                <div className="d-iblock fw-600">
                  <label className="d-iblock">
                    {weatherSummary &&
                      weatherSummary["temperature"]?.rightFooterBody}
                  </label>
                </div>
              </>
            </AmdMessage>
            <div className="d-flex">
              <AmdTable
                className="d-itable mr-sm-1"
                columns={[
                  { name: "image", columnLabel: "" },
                  { name: "name", columnLabel: "" },
                  { name: "value", columnLabel: "" },
                ]}
                data={summaryLeftTableData.map((x) => {
                  return Object.assign({}, x, {
                    image: (
                      <Image
                        className="pl-sm-1"
                        style={imageStyle}
                        src={x.image}
                      />
                    ),
                    name: <span>{x.name}</span>,
                    value: <span className="fw-600">{x.value}</span>,
                  });
                })}
                tableType="stripped_rows"
              />
              <AmdTable
                className="d-block"
                columns={[
                  { name: "image", columnLabel: "" },
                  { name: "name", columnLabel: "" },
                  { name: "value", columnLabel: "" },
                ]}
                data={summaryRightTableData.map((x) => {
                  return Object.assign({}, x, {
                    image: (
                      <Image
                        className="pl-sm-1"
                        style={imageStyle}
                        src={x.image}
                      />
                    ),
                    name: <span>{x.name}</span>,
                    value: <span className="fw-600">{x.value}</span>,
                  });
                })}
                tableType="stripped_rows"
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default WeatherSummaryCard;

const imageStyle: React.CSSProperties = {
  width: "16px",
  height: "14px",
  marginRight: "10px",
};
