import {
  FontWeights,
  IStackProps,
  Stack,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import { DefaultButton, PrimaryButton, DialogFooter } from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AdminEnum from "../../../enum/AdminEnum";
import TagPickerEnum from "../../../enum/TagPickerEnum";
import { IRoleItem } from "../../../models/admin/IRoleItem";
import { AmdCheckbox } from "../../common/form-controls/controlled/Checkbox/AmdCheckbox";
import { AmdTagPickControl } from "../../common/form-controls/controlled/TagPicker/AmdTagPickControl";
import { AmdTextField } from "../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";

export interface IAddRolePopupProps {
  hideDialog: any;
  onModalPopupClose: any;
  onRoleAdded?: any;
  selectedRoleId?: string;
  selectedRole?: IRoleItem;
  airport: string;
}

export const AddRolePopup: React.FunctionComponent<IAddRolePopupProps> = (
  props: IAddRolePopupProps
) => {
  const methods = useForm();

  useEffect(() => {
    // reset default field values
    methods.reset(props?.selectedRole);
  }, []);

  const onAddRole = (data: any) => {
    // Reset the form field values and close the popup.
    methods.reset({});
    props.onModalPopupClose();

    if (props.onRoleAdded) {
      props.onRoleAdded(props.selectedRoleId, data);
    }
  };

  const onErrors = (errors: any, _e: any) => {
    console.log(errors);
  };

  return (
    <div data-testid="addRolePopup">
      <AmdModal
        isOpen={!props.hideDialog}
        header="Add role"
        onClose={props.onModalPopupClose}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.body}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onAddRole, onErrors)}
              noValidate
            >
              <Stack horizontal tokens={stackTokens}>
                <Stack {...columnProps}>
                  <AmdTextField
                    controlId="name"
                    label="Name"
                    isMandatory={true}
                    isMultiline={false}
                    defaultValue={props.selectedRole?.name}
                  />

                  <label className="ms-Label fw-600">Data access</label>
                  <div className="ml-2">
                    {choiceGroupFieldJson().map((json: any) => {
                      const fieldValue = props.selectedRole
                        ? (props.selectedRole as any)[json.fieldName]
                        : "";
                      return (
                        <AmdCheckbox
                          key={json.fieldName}
                          controlId={json.fieldName}
                          label={json.label}
                          defaultValue={fieldValue}
                          className="chkbox mb-1"
                        />
                      );
                    })}
                  </div>

                  <AmdTagPickControl
                    controlId={AdminEnum.User.Organizations}
                    tagLabel="Organizations"
                    isMandatory={false}
                    suggestionType={TagPickerEnum.TagPickerType.Organizations}
                    airport={props.airport}
                  />

                  <AmdCheckbox
                    controlId="addToAllOrg"
                    label="Add to all organizations"
                    defaultValue={false}
                    className="chkbox"
                  />
                </Stack>
              </Stack>
              <DialogFooter>
                <DefaultButton
                  onClick={() => {
                    props.onModalPopupClose();
                  }}
                  text="Cancel"
                />
                <PrimaryButton type="submit" text="Add" />
              </DialogFooter>
            </form>
          </FormProvider>
        </div>
      </AmdModal>
    </div>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "50vh",
    width: "40vw !important",
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const stackTokens = { childrenGap: 2, root: { width: "100%" } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: "90vw" } },
};

const choiceGroupFieldJson = () => {
  return [
    {
      label: "Flights",
      fieldName: AdminEnum.RoleComponent.Flights,
      isMandatory: false,
    },
    {
      label: "Passengers",
      fieldName: AdminEnum.RoleComponent.Passengers,
      isMandatory: false,
    },
    {
      label: "Baggage",
      fieldName: AdminEnum.RoleComponent.Baggage,
      isMandatory: false,
    },
    {
      label: "Resource allocation",
      fieldName: AdminEnum.RoleComponent.ResourceAllocation,
      isMandatory: false,
    },
    {
      label: "Weather",
      fieldName: AdminEnum.RoleComponent.Weather,
      isMandatory: false,
    },

    {
      label: "Event management",
      fieldName: AdminEnum.RoleComponent.EventManagement,
      isMandatory: false,
    },
  ];
};
