import {
  Dropdown,
  IDropdownOption,
  Label,
  mergeStyleSets,
  TextField,
} from "@fluentui/react";
import {
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
} from "@fluentui/react/lib/Pickers";
import { FC, FormEvent } from "react";
import {
  filterSuggestedTags,
  getOpearatorOptions,
  getTextFromItem,
} from "../../../utils/tag-picker/TagPickerHelper";

const operatorOptions = getOpearatorOptions("string");
const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Airlines found",
  noResultsFoundText: "No Airlines found",
};

interface IQueryConditionProps {
  column: string;
  operator: IDropdownOption | undefined;
  setOperator: (operator: IDropdownOption) => void;
  airlines: ITag[] | undefined;
  setSelectedAirlines: (airlines: ITag[]) => void;
}

const QueryCondition: FC<IQueryConditionProps> = (
  props: IQueryConditionProps
) => {
  const itemLimit = props.operator?.key === "equal" ? 1 : undefined;

  const onOperatorChangeHandler = (
    _event: FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      props.setOperator(item);
    }
  };

  const onTagChangeHandler = (items?: ITag[] | undefined): void => {
    props.setSelectedAirlines(items || []);
  };

  return (
    <div
      className={`${contentStyles.queryCondition} amd-container-vcenter d-flex--wrap w-100`}
      data-testid="query-condition"
    >
      <TextField
        required
        disabled
        label="Column"
        defaultValue={props.column}
        aria-label="Filter Column"
        className={contentStyles.filterColumn}
      />
      <Dropdown
        className={contentStyles.filterOperator}
        label="Operator"
        selectedKey={props.operator ? props.operator.key : undefined}
        options={operatorOptions}
        onChange={onOperatorChangeHandler}
      />
      <div className={contentStyles.filterValue}>
        <Label htmlFor="Airline Picker" required>
          Value(s)
        </Label>
        <TagPicker
          aria-label="Airline Picker"
          removeButtonAriaLabel="Remove"
          selectionAriaLabel="Selected Airline"
          defaultSelectedItems={props.airlines}
          onResolveSuggestions={filterSuggestedTags}
          getTextFromItem={getTextFromItem}
          onChange={onTagChangeHandler}
          pickerSuggestionsProps={pickerSuggestionsProps}
          inputProps={{
            id: "Airline Picker",
          }}
          itemLimit={itemLimit}
        />
      </div>
    </div>
  );
};

export default QueryCondition;

const contentStyles = mergeStyleSets({
  queryCondition: {
    padding: "0px 0px 20px 24px",
    columnGap: "24px",
  },

  filterColumn: {
    width: "clamp(120px, 220px, 220px)",
  },
  filterOperator: {
    width: "clamp(100px, 160px, 160px)",
  },
  filterValue: {
    flexGrow: 2,
    maxWidth: "420px",
  },
});
