import { IResourceData } from "../../models/resource-allocation/IResourceData";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { AmdDonutChart } from "../common/charts/DonutChart/DonutChart";
import { ResourcesLegend } from "./ResourcesLegend";
export interface IResourcesItemProps {
  resourceDetail?: IResourceData[];
  resourceName?: string;
}
export const ResourcesItem: React.FunctionComponent<IResourcesItemProps> = (
  props: IResourcesItemProps
) => {
  return (
    <>
      <AmdDonutChart
        resourceData={props?.resourceDetail}
        noFlightsMessage={`No ${props.resourceName}`}
        hideLegend={true}
        hideValueInsideDonut={true}
        titleAlignment="center"
        chartAlignment="center"
        height={132}
        width={80}
        radius={50}
        title={props?.resourceName}
      />
      {props?.resourceDetail?.map((data: IResourceData) => {
        return (
          <ResourcesLegend
            key={CommonHelper.getGuid()}
            legendName={`${data.resourceTitle} ${props.resourceName}`}
            legendValue={`${data.resourceCount?.toString()}%`}
            legendColor={data.resourceColor}
          />
        );
      })}
    </>
  );
};
