import { Button, makeStyles } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import { AmdGlobalFilter } from "../../common/icons/filter/GlobalFilterIcon";
import GlobalFilterPopUp from "./GlobalFilterPopUp";

const GlobalFilter: React.FC = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const classes = useStyles();

  return (
    <div data-testid="global-filter">
      <Button
        appearance="primary"
        className={classes.filterIcon}
        onClick={showModal}
        icon={{
          as: "span",
          children: <AmdGlobalFilter />,
        }}
      >
        Global
      </Button>
      <GlobalFilterPopUp
        hideDialog={!isModalOpen}
        onModalPopupClose={hideModal}
      />
    </div>
  );
};

export default GlobalFilter;

const useStyles = makeStyles({
  filterIcon: {
    padding: "10px",
    margin: "0 10px",
  },
});
