import { PublicClientApplication } from "@azure/msal-browser";
import { ITag } from "@fluentui/react";
import { AmdSelectIndicator } from "../../components/common/icons/select-indicator/SelectIndicator";
import AdminEnum from "../../enum/AdminEnum";
import TagPickerEnum from "../../enum/TagPickerEnum";
import { IAddUserState, IUser } from "../../models/admin/IAddUser";
import IDimension, {
  IThreshold,
  IThresholdPayload
} from "../../models/admin/IDimension";
import { IOrganizationItem } from "../../models/admin/IOrganizationItem";
import { IRoleItem } from "../../models/admin/IRoleItem";
import AdminService from "../../services/admin/admin.service";
import { APIHelper } from "../api/APIHelper";
import { EventManagementHelper } from "../events/EventManagementHelper";

export abstract class AdminHelper {
  public static async getTerminals(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getTerminalsMethod = (accessToken: string, airportCode: string) => {
      return adminService.getTerminals(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getTerminalsMethod);
  }

  public static async getRoles(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getRolesMethod = (accessToken: string, airportCode: string) => {
      return adminService.getRoles(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getRolesMethod);
  }

  public static async getOrganizations(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getOrganizationsMethod = (
      accessToken: string,
      airportCode: string
    ) => {
      return adminService.getOrganizations(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getOrganizationsMethod);
  }

  public static async getResponsibilities(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getResponsibilitiesMethod = (
      accessToken: string,
      airportCode: string
    ) => {
      return adminService.getResponsibilities(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getResponsibilitiesMethod);
  }

  public static async getUsers(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getUsersMethod = (accessToken: string, airportCode: string) => {
      return adminService.getUsers(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getUsersMethod);
  }

  public static async getAirports(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getAirportsMethod = (accessToken: string) => {
      return adminService.getAirports(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, getAirportsMethod);
  }

  public static async getThresholdByDimension(
    msalInstance: PublicClientApplication,
    dimension: string,
    airportCode: string
  ): Promise<any> {
    const adminService = new AdminService();
    const getThresholdByDimensionMethod = (accessToken: string) => {
      return adminService.getThresholdByDimension(
        accessToken,
        dimension,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getThresholdByDimensionMethod);
  }

  public static async saveThresholdByDimension(
    msalInstance: PublicClientApplication,
    dimension: string,
    payload: IThresholdPayload[],
    airportCode: string
  ): Promise<any> {
    const adminService = new AdminService();
    const saveThresholdByDimensionMethod = (
      accessToken: string,
      payload: IThresholdPayload[]
    ) => {
      return adminService.saveThresholdByDimension(
        accessToken,
        dimension,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      payload,
      saveThresholdByDimensionMethod
    );
  }

  public static async getRolesForComponents(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const adminService = new AdminService();
    const getRolesForComponentsMethod = (accessToken: string) => {
      return adminService.getRolesForComponents(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, getRolesForComponentsMethod);
  }

  public static async getRoleComponentById(
    msalInstance: PublicClientApplication,
    roleId: string
  ): Promise<IRoleItem | Error> {
    const adminService = new AdminService();
    const getRoleComponentByIdMethod = (accessToken: string) => {
      return adminService.getRoleComponentById(accessToken, roleId);
    };

    return APIHelper.CallAPI(msalInstance, null, getRoleComponentByIdMethod);
  }

  public static async getSuggestions(
    type: string,
    msalInstance: any,
    airport: string,
    eventId?: string
  ) {
    if (type === TagPickerEnum.TagPickerType.Roles) {
      return AdminHelper.getRoles(msalInstance);
    } else if (type === TagPickerEnum.TagPickerType.Organizations) {
      return AdminHelper.getOrganizations(msalInstance);
    } else if (type === TagPickerEnum.TagPickerType.Responsibilities) {
      return AdminHelper.getResponsibilities(msalInstance);
    } else if (type === TagPickerEnum.TagPickerType.Terminals) {
      return AdminHelper.getTerminals(msalInstance);
    } else if (type === TagPickerEnum.TagPickerType.Users) {
      return AdminHelper.getUsers(msalInstance);
    } else if (type === TagPickerEnum.TagPickerType.Channel) {
      return EventManagementHelper.getEventChannels(
        msalInstance,
        "",
        airport,
        eventId
      );
    } else if (type === TagPickerEnum.TagPickerType.Kpi) {
      return EventManagementHelper.getKpi(msalInstance);
    } else {
      return Promise.reject(new Error("Invalid suggestion type"));
    }
  }

  public static getUpdatedUserState(
    stateObj: IAddUserState,
    fieldName: string,
    selectedValue: any
  ) {
    switch (fieldName) {
      case AdminEnum.User.Name:
        stateObj = { ...stateObj, name: selectedValue };
        break;
      case AdminEnum.User.Email:
        stateObj = { ...stateObj, email: selectedValue };
        break;
      case AdminEnum.User.Roles:
        stateObj = { ...stateObj, roles: selectedValue };
        break;
      case AdminEnum.User.Responsibilities:
        stateObj = { ...stateObj, responsibilities: selectedValue };
        break;
      case AdminEnum.User.Organizations:
        stateObj = { ...stateObj, organization: selectedValue };
        break;
      case AdminEnum.User.Terminals:
        stateObj = { ...stateObj, terminals: selectedValue };
        break;
    }

    return stateObj;
  }

  public static getDefaultUserState() {
    return {
      name: "",
      email: "",
      roles: [""],
      responsibilities: [""],
      organization: [""],
      terminals: [""],
    };
  }

  public static getUserItems(users: any[]) {
    let items: IUser[] = [];
    items = users?.map((user) => ({
      name: user.name,
      email: user.email,
      key: user.id,
      role: user.roles,
      organization: user.organization?.name,
      responsibleArea: user.responsibilities,
      userTerminal: user.terminals?.map((ter: any) => ({ id: ter, name: ter })),
    }));
    return items;
  }

  public static isValid = (isFormValid: boolean, value?: any) => {
    return isFormValid && !value ? "Field is required" : "";
  };

  public static isFormValid = (form: IAddUserState) => {
    return !!(form.name && form.email);
  };

  public static getUpdatedDimensions(
    selectedDimension: IDimension,
    dimensionDetails: any[]
  ) {
    const sel = { ...selectedDimension };
    const updatedThreshold = selectedDimension.thresholds?.map(
      (threshold: IThreshold, index: number) => ({
        ...threshold,
        earlyBuffer: dimensionDetails[index].earlyBuffer,
        lateBuffer: dimensionDetails[index].lateBuffer,
        poor: { min: 0, max: dimensionDetails[index].amberToRed - 1 },
        moderate: {
          min: dimensionDetails[index].amberToRed,
          max: dimensionDetails[index].greenToAmber - 1,
        },
        green: { min: dimensionDetails[index].greenToAmber, max: 100 },
      })
    );
    return {
      ...sel,
      thresholds: updatedThreshold,
    };
  }

  public static getSaveDimensionPayload(dimension: IDimension) {
    return dimension.thresholds?.map((t: IThreshold) => ({
      type: t.type,
      earlyBuffer: t.earlyBuffer,
      lateBuffer: t.lateBuffer,
      greenToAmber: t.green.min,
      amberToRed: t.moderate.min,
    }));
  }

  public static getListClassName(name: string, dimensionName: string) {
    return name === dimensionName ? "bg--white" : "";
  }
  public static isSelectedClassName(name: string, dimensionName: string) {
    return name === dimensionName;
  }

  public static async getOrganizationsForComponents(
    msalInstance: PublicClientApplication
  ): Promise<IOrganizationItem[]> {
    const adminService = new AdminService();
    const getOrganizationForComponentsMethod = (accessToken: string) => {
      return adminService.getOrganizationsForComponents(accessToken);
    };

    return APIHelper.CallAPI(
      msalInstance,
      null,
      getOrganizationForComponentsMethod
    );
  }

  public static async getOrganizationComponentById(
    msalInstance: PublicClientApplication,
    organizationId: string
  ): Promise<IOrganizationItem | Error> {
    const adminService = new AdminService();
    const getOrganizationComponentByIdMethod = (accessToken: string) => {
      return adminService.getOrganizationComponentById(
        accessToken,
        organizationId
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      null,
      getOrganizationComponentByIdMethod
    );
  }

  public static getRoleComponentCellContent = (cellValue: boolean) => {
    return cellValue ? <AmdSelectIndicator /> : "";
  };

  public static getAddedRoleDetails = (
    rolesList: IRoleItem[],
    roleId: string,
    data: any
  ) => {
    if (roleId) {
      const index = rolesList.findIndex((role: any) => role.id === roleId);
      if (index !== -1) {
        rolesList[index].id = roleId;
        rolesList[index].name = data.name;
        rolesList[index].flights = data.flights;
        rolesList[index].passengers = data.passengers;
        rolesList[index].baggage = data.baggage;
        rolesList[index].resourceallocation = data.resourceallocation;
        rolesList[index].weather = data.weather;
        rolesList[index].eventmanagement = data.eventmanagement;
      }
      return [...rolesList];
    } else {
      return [
        ...rolesList,
        {
          name: data.name,
          flights: data.flights,
          passengers: data.passengers,
          baggage: data.baggage,
          resourceallocation: data.resourceallocation,
          weather: data.weather,
          eventmanagement: data.eventmanagement,
        },
      ];
    }
  };

  public static getOrganizationComponentCellContent = (cellValue: boolean) => {
    return cellValue ? cellValue : "";
  };
  public static getAddedOrganizationDetails = (
    organizationsList: IOrganizationItem[],
    orgId: string,
    data: any
  ) => {
    if (orgId) {
      const index = organizationsList.findIndex((org: any) => org.id === orgId);
      if (index !== -1) {
        organizationsList[index].id = orgId;
        organizationsList[index].name = data.organizationName;
        organizationsList[index].roles = data.roles[0].name;
      }
      return [...organizationsList];
    } else {
      return [
        ...organizationsList,
        {
          name: data.organizationName,
          roles: data.roles[0].name,
        },
      ];
    }
  };

  public static getRolesTags = (data: any[]) => {
    let itemTags: ITag[] = [];
    if (data) {
      itemTags = data.map((d: any) => ({
        key: d.id,
        name: d.name,
      }));
    }

    return itemTags;
  };
}
