import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { MultiAirportHelper } from "../../utils/multi-airports/MultiAirportHelper";
import {
  calculateAirportStatusCount,
  filterAirportsByStatus,
} from "../../utils/multi-airports/MultiAirportHelperFunctions";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import GlobalFilter from "../filters/global-filter/GlobalFilter";
import { AirportDataView } from "./airportDataView";
import { AirportPerformance } from "./AirportPerformance";

export const MultiAirport: React.FunctionComponent = () => {
  const [airportList, setAirportList] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [airportSearch, setAirportSearch] = useState([]);

  const airport = useAppSelector((state) => getAirportCode(state));

  useEffect(() => {
    let isMounted = true;

    MultiAirportHelper.getAirports(msalInstance).then((response: any) => {
      if (isMounted) {
        setAirportList(response);
        setFilterData(response);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const filterAirportList = (tagStatusType: any) => {
    const filteredData = filterAirportsByStatus(airportList, tagStatusType);
    setFilterData(filteredData);
  };

  const updatePinnedStatus = (id: string) => {
    const updatedData = airportList.map((item: any) =>
      item.id === id ? { ...item, isPinned: !item.isPinned } : item
    );
    const updatedFilterData = filterData.map((item: any) =>
      item.id === id ? { ...item, isPinned: !item.isPinned } : item
    );
    setFilterData(updatedFilterData);
    setAirportList(updatedData);
  };

  const airports = airportSearch?.length ? airportSearch : airportList;
  const airportStatusCount = calculateAirportStatusCount(airportList);

  return (
    <>
      <NotificationBanner airport={airport} />
      <section className="amd-container-hspacebtwn amd-container-vcenter">
        <div
          className={`d-flex ${contentStyles.airportHeading} fw-250 fs-40`}
          data-testid="multi-airport-container"
        >
          Airports
        </div>
        <div className="d-flex amd-container-vcenter ">
          <GlobalFilter />
        </div>
      </section>
      <AirportPerformance
        airportData={airportList}
        filterAirport={filterAirportList}
        setAirportSearch={setAirportSearch}
        airportStatusCount={airportStatusCount}
      />

      {airportList && airportList.length > 0 && (
        <AirportDataView
          filteredData={filterData}
          searchData={airports}
          onPinClickHandler={updatePinnedStatus}
        />
      )}
    </>
  );
};

const contentStyles = mergeStyleSets({
  airportHeading: {
    fontfamily: "Amadeus",
    color: "#005eb8",
  },
});
