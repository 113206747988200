import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import FlightsEnum from "../../enum/FlightsEnum";
import ResourceEnum from "../../enum/ResourceEnum";
import { useApiCall } from "../../hooks/useApiCall";
import DateHelper from "../../utils/common/DateHelper";
import {
  RESOURCE_ALLOCATION_TABS,
  RESOURCE_TABS,
} from "../../utils/resource/ResourceConstants";
import { ResourceHelper } from "../../utils/resource/ResourceHelper";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { AmdTabs } from "../common/tabs/tabs";
import { FlightTypeFilter } from "../filters/flight-type/FlightTypeFilter";
import { ResourceAllocationKPI } from "./ResourceAllocationKPI";

export const ResourceAllocationMacroCard: React.FunctionComponent<
  IResourceAllocationMacroCardProps
> = ({ showList = false, airport }) => {
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedTerminal, setSelectedTerminal] = useState<string>("2");
  const [selectedRange, setSelectedRange] = useState<number>();
  const [selectedType, setSelectedType] = useState<string>("arrival;departure");
  const [resourceAvailabilityData, setResourceAvailabilityData] = useState<
    any[] | undefined
  >();
  const [resourceAllocationDetails, setResourceAllocationDetails] = useState<
    any[] | undefined
  >();

  useEffect(() => {
    setSelectedTab(ResourceHelper.getResourceAllocationDefaultTab());
  }, []);

  const dateStr = DateHelper.getDefaultDateStrForAirport(airport);
  const resourceAvailabilityUrls: string[] = [];
  const resourceAllocationUrls: string[] = [];

  const resource = (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[
    selectedTab
  ];
  const legType = selectedType !== FlightsEnum.LegType.All ? selectedType : "";

  resource?.forEach((res: any) => {
    resourceAvailabilityUrls.push(
      `${process.env.REACT_APP_API_RESOURCEAVAILABILITY?.replace(
        "{airportCode}",
        airport
      )}/${res.value}/${dateStr}?resourceSubType=${
        res.subType
      }&direction=${legType}`
    );
  });

  if (selectedTerminal) {
    resource?.forEach((res: any) => {
      resourceAllocationUrls.push(
        `${process.env.REACT_APP_API_RESOURCEALLOCATION?.replace(
          "{airportCode}",
          airport
        )}/${res.value}/${selectedTerminal}/${dateStr}?resourceSubType=${
          res.subType
        }&direction=${legType}`
      );
    });
  }

  const {
    data: resourceAvailabilityDataResponse,
    error: resourceAvailabilityError,
    isLoading: resourceAvailabilityIsLoading,
  } = useApiCall<any[]>({
    url: resourceAvailabilityUrls,
    dependencies: [airport, selectedTab, selectedType],
  });

  const {
    data: resourceAllocationDataResponse,
    error: resourceAllocationError,
    isLoading: resourceAllocationIsLoading,
  } = useApiCall<any[]>({
    url: resourceAllocationUrls,
    dependencies: [
      airport,
      selectedTerminal,
      selectedTab,
      selectedType,
      selectedRange,
    ],
  });

  useEffect(() => {
    if (resourceAvailabilityDataResponse) {
      const responseObj = resourceAvailabilityDataResponse.map(
        (res, index) => ({
          key: (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[
            selectedTab
          ][index]?.title,
          data: ResourceHelper.getResponseData(res),
          showKPI: ResourceHelper.getShowKPI(
            selectedTab,
            (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[selectedTab][
              index
            ]?.value,
            selectedType
          ),
        })
      );
      setResourceAvailabilityData(responseObj);
    }
  }, [resourceAvailabilityDataResponse]);

  useEffect(() => {
    if (resourceAllocationDataResponse) {
      const updatedResponses = ResourceHelper.addTotalInResponse(
        resourceAllocationDataResponse
      );
      const resultArr = ResourceHelper.updateAPIResponse(
        updatedResponses,
        selectedRange
      );
      const responseObj = resultArr.map((res, index) => ({
        key: (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[selectedTab][
          index
        ]?.name,
        data: ResourceHelper.getResponseData(res),
        range: res.range,
      }));

      setResourceAllocationDetails(responseObj);
    }
  }, [resourceAllocationDataResponse]);

  const onResourceTabClick = (_selectedtab: string) => {
    setSelectedTab(_selectedtab);
    setSelectedTerminal("");
    setSelectedRange(0);
  };

  const onResourceTypeChange = (_selectedType: string) => {
    setSelectedType(_selectedType);
    const updatedObj = resourceAvailabilityData?.map((resource, index) => ({
      ...resource,
      showKPI: ResourceHelper.getShowKPI(
        selectedTab,
        (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[selectedTab][index]
          ?.value,
        _selectedType
      ),
    }));
    setResourceAvailabilityData(updatedObj);
  };

  const onSelectTerminal = (_terminal: string) => {
    setSelectedTerminal(_terminal);
    setSelectedRange(0);
  };

  const onSelectRange = (_range: string) => {
    setSelectedRange(Number(_range));
  };

  return (
    <div data-testid="resourceAllocationMacroCard">
      <FlightTypeFilter
        onChange={(changedFlightType) => {
          onResourceTypeChange(changedFlightType);
        }}
        selectedType={selectedType}
      />
      <AmdTabs
        dataTestId="ResourceAllocationTab"
        defaultSelectedTab={ResourceEnum.ResourceTabs.BeltsAndChutes}
        tabList={RESOURCE_TABS.data}
        onTabClick={onResourceTabClick}
        selectedTab={selectedTab}
      />
      {(resourceAllocationError || resourceAvailabilityError) && (
        <NoDataCard message="No Resource data found" />
      )}

      {(resourceAllocationIsLoading || resourceAvailabilityIsLoading) && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "20vh" }}
        />
      )}
      {resourceAllocationDetails &&
        resourceAvailabilityData &&
        selectedTab !== ResourceEnum.ResourceTabs.SelfServiceCounter && (
          <ResourceAllocationKPI
            onSelectTerminal={onSelectTerminal}
            onSelectRange={onSelectRange}
            showList={showList}
            legType={selectedType}
            selectedResourceTab={selectedTab}
            resourceAllocationData={resourceAllocationDetails}
            resourceAvailabilityData={resourceAvailabilityData}
            range={selectedRange}
            airport={airport}
          />
        )}
    </div>
  );
};

export interface IResourceAllocationMacroCardProps {
  showList?: boolean;
  airport: string;
}
