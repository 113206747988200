import { IColumnsType } from "./types";

export function getSelectedColumn(columns: IColumnsType[], fieldName: string) {
  if (!columns) {
    return [];
  }
  const match = columns?.filter(
    (col) => col?.fieldName?.toLowerCase() === fieldName?.toLowerCase()
  );
  return match;
}
