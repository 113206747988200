import { CommonHelper } from "../../../utils/common/CommonHelper";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdAirplane } from "../../common/icons/airplane/airplane";
import { AmdBaggage } from "../../common/icons/baggage/baggage";
import { AmdEvent } from "../../common/icons/event/Event";
import { AmdFacility } from "../../common/icons/facility/facility";
import { AmdNotification } from "../../common/icons/notification/notification";
import { AmdPassenger } from "../../common/icons/passenger/passenger";
import { AmdUserSettings } from "../../common/icons/user-settings/user-settings";

export const FlyoutMenu: React.FunctionComponent = () => {
  const isInTeams = CommonHelper.isInTeams();
  return (
    <>
      <div
        className={`amd-flyout ${
          isInTeams ? "amd-flyout--teams" : "amd-flyout--app"
        }`}
        data-testid="flyout-menu"
      >
        <AmdButton
          type={AmdButtonType.ICON_BUTTON}
          onClick={() => CommonHelper.scrollIntoView("flightInformationStack")}
          className="mb-4"
          testId="flight-icon"
        >
          <AmdAirplane />
        </AmdButton>
        <AmdButton
          type={AmdButtonType.ICON_BUTTON}
          onClick={() => CommonHelper.scrollIntoView("resourceAllocationStack")}
          className="mb-4"
          testId="resource-icon"
        >
          <AmdFacility />
        </AmdButton>
        <AmdButton
          testId="passengers-icon"
          type={AmdButtonType.ICON_BUTTON}
          onClick={() => CommonHelper.scrollIntoView("passengersStack")}
          className="mb-4"
        >
          <AmdPassenger />
        </AmdButton>
        <AmdButton
          testId="baggage-icon"
          type={AmdButtonType.ICON_BUTTON}
          className="mb-4"
        >
          <AmdBaggage />
        </AmdButton>
        <AmdButton
          testId="notifications-icon"
          type={AmdButtonType.ICON_BUTTON}
          onClick={() => CommonHelper.scrollIntoView("notificationsStack")}
          className="mb-4"
        >
          <AmdNotification />
        </AmdButton>
        {!isInTeams && (
          <AmdButton
            testId="admin-icon"
            type={AmdButtonType.ICON_BUTTON}
            className="mb-4"
            onClick={() => CommonHelper.handleRedirects("ADMIN")}
          >
            <AmdUserSettings />
          </AmdButton>
        )}
        <AmdButton
          testId="event-icon"
          type={AmdButtonType.ICON_BUTTON}
          onClick={() => CommonHelper.scrollIntoView("event-management-card")}
        >
          <AmdEvent />
        </AmdButton>
      </div>
    </>
  );
};
