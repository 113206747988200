import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdDownIcon: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width={props.width}
        height={props.height}
        viewBox={props.viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.79289 8.29289C5.18342 7.90237 5.81658 7.90237 6.20711 8.29289L12.5 14.5858L18.7929 8.29289C19.1834 7.90237 19.8166 7.90237 20.2071 8.29289C20.5976 8.68342 20.5976 9.31658 20.2071 9.70711L13.2071 16.7071C12.8166 17.0976 12.1834 17.0976 11.7929 16.7071L4.79289 9.70711C4.40237 9.31658 4.40237 8.68342 4.79289 8.29289Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdDownIcon.defaultProps = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  color: "#005EB8",
};
