import { PublicClientApplication } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import NOTIFICATION_DATA from "../../data/notification/notification.json";
import NotificationEnum from "../../enum/NotificationEnum";
import PageEnum from "../../enum/PageEnum";
import INotification, {
  INotificationItem,
} from "../../models/notification/INotification";
import NotificationService from "../../services/notification/notification.service";
import { APIHelper } from "../api/APIHelper";
import { CommonHelper } from "../common/CommonHelper";

export abstract class NotificationHelper {
  public static async getClientAccessUrl(
    microsoftTeams: any,
    msalInstance: PublicClientApplication,
    hubList: string[],
    airportCode: string
  ): Promise<any> {
    const notificationService = new NotificationService();
    const payLoad = { hubList: hubList };
    const userEmail = (await CommonHelper.getLoggedInUser(
      microsoftTeams,
      msalInstance
    )) as string;
    const getClientAccessUrlMethod = (accessToken: string, payLoad: any) => {
      return notificationService.getClientAccessUrl(
        accessToken,
        payLoad,
        userEmail,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payLoad, getClientAccessUrlMethod);
  }

  public static async getNotificationById(
    msalInstance: PublicClientApplication,
    notificationId: string,
    airportCode: string
  ): Promise<any> {
    const notificationService = new NotificationService();
    const getNotificationByIdMethod = (accessToken: string) => {
      return notificationService.getNotificationById(
        accessToken,
        notificationId,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getNotificationByIdMethod);
  }

  public static getPubSubHubs = (pathName: string) => {
    const hubs: string[] = [];
    if (pathName.toLowerCase() === `/${PageEnum.PageUrl.Notifications}`) {
      hubs.push(NotificationEnum.PubSubHub.Notifications);
    } else {
      hubs.push(NotificationEnum.PubSubHub.Alerts);
    }

    return hubs;
  };

  public static checkTokenExpiration(token: string) {
    const decoded: any = jwt_decode(token);
    if (decoded.exp) {
      const expirationTime = decoded.exp;
      const currentTime = Date.now() / 1000;
      return expirationTime - currentTime > 0;
    }
    return false;
  }

  public static saveNotifications(updatedList: INotification[]) {
    localStorage.setItem("apocNotifications", JSON.stringify(updatedList));
  }

  public static getNewSockets(
    clientUrlData: any,
    notificationsList: INotification[]
  ) {
    const newWebSockets: WebSocket[] = [];
    if (clientUrlData) {
      clientUrlData.clientURLs.forEach((client: any) => {
        newWebSockets.push(new WebSocket(client.clientURL));
        notificationsList
          ?.filter((n) => n.hub === client.hubName)
          .forEach((obj) => (obj.clientAccessUrl = client.clientURL));
      });
      this.saveNotifications(notificationsList);
    }
    return newWebSockets;
  }

  public static removeNotifications(
    notificationsList: INotification[],
    item: INotificationItem,
    isRedirect: boolean
  ) {
    const updatedList = notificationsList.map((notification) => {
      let updatedNotificationList: INotificationItem[] =
        notification.notificationItems;
      updatedNotificationList = notification.notificationItems?.filter(
        (n) => n.id !== item.id
      );

      return {
        ...notification,
        notificationItems: updatedNotificationList,
      };
    });
    this.saveNotifications(updatedList);
    if (isRedirect) {
      CommonHelper.handleRedirects(item.category?.toUpperCase());
    }
  }

  public static processEvent(
    hubName: string,
    notificationItems: INotificationItem[],
    notificationsList: INotification[],
    message: any
  ) {
    const isAvailable = notificationItems.find((n) => n.id === message.id);
    let newNotification: INotificationItem = {
      severity: "",
      id: "",
      airportCode: "",
      title: "",
      body: "",
      category: "",
      timestamp: "",
    };
    if (!isAvailable) {
      newNotification = {
        severity: message.severity,
        id: message.id,
        airportCode: message.airportCode,
        title: message.title,
        body: message.body,
        category: message.category,
        timestamp: message.timestamp,
      };
      notificationsList
        ?.filter((n) => n.hub.toLowerCase() === hubName.toLowerCase())
        .forEach((obj) => obj.notificationItems.push(newNotification));
      this.saveNotifications(notificationsList);
    }
    return newNotification;
  }

  public static getNotificationList() {
    const notificationObj = localStorage.getItem("apocNotifications");
    let notificationsList: INotification[] = [];
    if (notificationObj) {
      notificationsList = JSON.parse(notificationObj);
    } else {
      notificationsList = NOTIFICATION_DATA.data;
    }
    return notificationsList;
  }

  public static getSockets(notificationsList: INotification[]) {
    const expiredHubList: string[] = [];
    const webSocket: WebSocket[] = [];
    notificationsList.forEach((notification) => {
      if (notification.clientAccessUrl) {
        //check clientAccessUrl expiration
        const accessToken =
          notification.clientAccessUrl.split("access_token=")[1];
        const isValidToken = this.checkTokenExpiration(accessToken);
        if (isValidToken) {
          webSocket.push(new WebSocket(notification.clientAccessUrl));
        } else {
          expiredHubList.push(notification.hub);
        }
      } else {
        expiredHubList.push(notification.hub);
      }
    });
    return { expiredHubList: expiredHubList, webSocket: webSocket };
  }

  public static getAllSavedNotificationItems(
    notificationsList: INotification[],
    hubs: string[]
  ) {
    const notificationListMap = notificationsList
      .filter((notification) => {
        return hubs.includes(notification.hub);
      })
      .map((notification) => notification?.notificationItems);

    return notificationListMap.length > 0
      ? notificationListMap.reduce((prev, current) => [...prev, ...current])
      : [];
  }

  public static getReversedAlerts(notifications: any[]) {
    return notifications.reverse();
  }

  public static getExpansionClass(expanded: boolean, isStackedAlert: boolean) {
    if (isStackedAlert) {
      return expanded ? "expanded" : "";
    }
    return expanded ? "expanded_last" : "";
  }

  public static getHiddenClass(expanded: boolean) {
    return expanded ? "" : "hidden";
  }

  public static getAlertHeight(alertCount: number) {
    return alertCount > 0 ? 60 : 0;
  }

  public static getTopValue(
    expanded: boolean,
    positionCounter: number,
    defaultVal: number
  ) {
    return `${expanded ? positionCounter : defaultVal}px`;
  }

  public static getPositionCounter(alertCount: number) {
    return (alertCount - 1) * 47;
  }

  public static getRealIndex(alertCount: number, index: number) {
    return alertCount - index - 1;
  }

  public static getReadClass(read: boolean) {
    return !read ? "2px 0px 0px 0px #005EB8 inset" : "none";
  }
}
