import IAirportSummary from "../../models/multi-airports/IAirportSummary";
import {
  getCommonAirports,
  splitAirportDataByPin,
} from "../../utils/multi-airports/MultiAirportHelperFunctions";
import { AirportContainer } from "./AirportContainer";

export interface IAirportDataViewProps {
  searchData: IAirportSummary[];
  filteredData: IAirportSummary[];
  onPinClickHandler: (id: string) => void;
}

export const AirportDataView: React.FunctionComponent<IAirportDataViewProps> = (
  props: IAirportDataViewProps
) => {
  const onPinClickHandler = (pinId: any) => {
    props.onPinClickHandler(pinId);
  };

  const commonAirports = getCommonAirports(
    props.searchData,
    props.filteredData
  );

  const { pinnedAirports, unPinnedAirports } =
    splitAirportDataByPin(commonAirports);

  return (
    <div data-testid="airport-data-view">
      <section>
        {pinnedAirports.map((airportDetail: IAirportSummary) => {
          return (
            <AirportContainer
              key={airportDetail.id}
              id={airportDetail.id}
              code={airportDetail.code}
              name={airportDetail.name}
              location={airportDetail.location}
              isPinned={airportDetail.isPinned}
              weatherCode={airportDetail.weatherCode}
              weatherValue={airportDetail.weatherValue}
              weatherUnit={airportDetail.weatherUnit}
              weatherSummary={airportDetail.weatherSummary}
              dimensions={airportDetail.dimensions}
              pinnedValue={onPinClickHandler}
            />
          );
        })}

        <div>
          {unPinnedAirports.map((airportDetail: IAirportSummary) => {
            return (
              <AirportContainer
                key={airportDetail.id}
                id={airportDetail.id}
                code={airportDetail.code}
                name={airportDetail.name}
                location={airportDetail.location}
                isPinned={airportDetail.isPinned}
                weatherCode={airportDetail.weatherCode}
                weatherValue={airportDetail.weatherValue}
                weatherUnit={airportDetail.weatherUnit}
                weatherSummary={airportDetail.weatherSummary}
                dimensions={airportDetail.dimensions}
                pinnedValue={onPinClickHandler}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};
