import { SearchBox, SearchBoxChangeEvent } from "@fluentui/react-components";
import React from "react";
import IAirportSummary from "../../models/multi-airports/IAirportSummary";
import { findMatches } from "../../utils/multi-airports/MultiAirportHelperFunctions";
import { AirportStatusTags } from "./AirportStatusTags";

export interface IAirportPerformance {
  airportData: IAirportSummary[];
  filterAirport: (tagStatusType: string) => void;
  setAirportSearch: (matchedAirports: any) => void;
  airportStatusCount: any;
}

export const AirportPerformance: React.FunctionComponent<
  IAirportPerformance
> = (props: IAirportPerformance) => {
  const { airportData, setAirportSearch } = props;

  const onTagClickHandler = (tagStatusType: string) => {
    props.filterAirport(tagStatusType);
  };

  function searchInputHandler(event: SearchBoxChangeEvent) {
    const { target } = event;
    const { value } = target as HTMLInputElement;
    const matchedAirports = findMatches(value, airportData);
    setAirportSearch(matchedAirports);
  }

  return (
    <section
      className="mt-2 mb-3 amd-container-vcenter"
      data-testid="airport-performance"
    >
      <SearchBox
        placeholder="Search airport"
        onChange={(event: SearchBoxChangeEvent) => {
          searchInputHandler(event);
        }}
        size="medium"
        appearance="filled-lighter"
        aria-label="search"
      />
      <div className="ml-2">
        <AirportStatusTags
          airportStatusCount={props.airportStatusCount}
          onTagClickHandler={onTagClickHandler}
        />
      </div>
    </section>
  );
};
