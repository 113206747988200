import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { USER_COLUMNS } from "../../../utils/admin/AdminConstants";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdUser } from "../../common/icons/user/User";
import { AddUserPopup } from "./AddUserPopup";

export interface IUsersProps {
  airport: string;
}

export const Users: React.FunctionComponent<IUsersProps> = ({ airport }) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [userList, setUserList] = useState<any>([]);

  const userColumns = USER_COLUMNS;

  useEffect(() => {
    let isMounted = true;

    async function fetchUsers() {
      const users = await AdminHelper.getUsers(msalInstance);

      if (isMounted) {
        setUserList(users.data);
      }
    }
    fetchUsers();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div data-testid="users">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        <div className="d-flex ai-center">
          <div className="ff-amd fs-36 fw-400">Users</div>
        </div>

        <AmdButton
          testId="addUser"
          text="Add user"
          onClick={showModal}
          type={AmdButtonType.DEFAULT}
          prefixImageIconBeforeText={true}
          imageIcon={<AmdUser className="mt-1 mr-1" />}
        />
      </div>
      <div className="mt-2">
        {userList && userList.length > 0 && (
          <AmdDataGrid
            showActionButtons={true}
            rowItems={AdminHelper.getUserItems(userList)}
            columns={userColumns}
            infoHeaders={["Name"]}
          />
        )}

        <AddUserPopup
          hideDialog={!isModalOpen}
          onModalPopupClose={hideModal}
          airport={airport}
        ></AddUserPopup>
      </div>
    </div>
  );
};
