import { Stack } from "@fluentui/react";
import PageEnum from "../../enum/PageEnum";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { homeContainer } from "../common/AppStyles";
import EventManagementCard from "../common/event-management-card/EventManagementCard";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../common/icons/arrow/arrow";
import { ArrowDirection } from "../common/icons/arrow/arrowDirection";
import { MultiAirportPanel } from "../common/multi-airports/MultiAirportPanel";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { FlightsMacroCard } from "../flights/flights-360/FlightsMacroCard";
import { HOPMacroCard } from "../health-of-operations/HOPMacroCard";
import { NotificationMacroCard } from "../notifications/NotificationMacroCard";
import { PassengerMacroCard } from "../pax/PassengerMacroCard";
import { ResourceAllocationMacroCard } from "../resources-allocation/ResourceAllocationMacroCard";
import { FlyoutMenu } from "./components/FlyoutMenu";
import { HomeKPI } from "./kpi/HomeKPI";

export const Home: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <>
      <Stack
        enableScopedSelectors
        className={homeContainer}
        data-testid="homeContainer"
      >
        <NotificationBanner airport={airport} />
        <>
          <div className="amd-section-shadow mb-2" id="multiAirportBar">
            <MultiAirportPanel airport={airport} />
          </div>

          <div className="amd-section-shadow mb-3" id="hoaStack">
            <HOPMacroCard airport={airport} />
          </div>
          <div className="amd-section-shadow mb-3" id="notificationsStack">
            <header
              className="amd-section-shadow__header amd-container-hspacebtwn"
              data-testid="notificationsContainer"
            >
              <span>Notifications</span>
              <AmdButton
                id="notifications"
                testId="notifications"
                type={AmdButtonType.ICON_BUTTON}
                onClick={() =>
                  CommonHelper.navigateToComponent(
                    `/${PageEnum.PageUrl.Notifications}`,
                    "",
                    PageEnum.PageUrl.Notifications
                  )
                }
              >
                <AmdArrow direction={ArrowDirection.RIGHT} />
              </AmdButton>
            </header>
            <NotificationMacroCard />
          </div>
          <div className="amd-section-shadow mb-3" id="quickInfoStack">
            <HomeKPI airport={airport} />
          </div>
          <div className="amd-section-shadow mb-3" id="flightInformationStack">
            <header
              className="amd-section-shadow__header amd-container-hspacebtwn"
              data-testid="flightsContainer"
            >
              <span>Flight Information</span>
              <AmdButton
                id="flightInformation"
                testId="flightInformation"
                type={AmdButtonType.ICON_BUTTON}
                onClick={() =>
                  CommonHelper.navigateToComponent(
                    `/${PageEnum.PageUrl.Flights360}`,
                    "",
                    PageEnum.PageUrl.Flights360
                  )
                }
              >
                <AmdArrow direction={ArrowDirection.RIGHT} />
              </AmdButton>
            </header>
            <FlightsMacroCard isDetailsPage={false} airport={airport} />
          </div>
          <div className="amd-section-shadow mb-3" id="resourceAllocationStack">
            <header
              className="amd-section-shadow__header amd-container-hspacebtwn"
              data-testid="resourceAllocationContainer"
            >
              <span>Resource Allocation</span>
              <AmdButton
                id="resourceAllocation"
                testId="resourceAllocation"
                type={AmdButtonType.ICON_BUTTON}
                onClick={() =>
                  CommonHelper.navigateToComponent(
                    `/${PageEnum.PageUrl.ResourceAllocation}`,
                    "",
                    PageEnum.PageUrl.ResourceAllocation
                  )
                }
              >
                <AmdArrow direction={ArrowDirection.RIGHT} />
              </AmdButton>
            </header>
            <ResourceAllocationMacroCard showList={false} airport={airport} />
          </div>
          <div className="amd-section-shadow mb-3" id="passengersStack">
            <header
              className="amd-section-shadow__header amd-container-hspacebtwn"
              data-testid="passengersContainer"
            >
              <span>Passengers Flow</span>
              <AmdButton
                id="passengersFlow"
                testId="passengersFlow"
                type={AmdButtonType.ICON_BUTTON}
                onClick={() =>
                  CommonHelper.navigateToComponent(
                    `/${PageEnum.PageUrl.Passenger}`,
                    "",
                    PageEnum.PageUrl.Passenger
                  )
                }
              >
                <AmdArrow direction={ArrowDirection.RIGHT} />
              </AmdButton>
            </header>
            <PassengerMacroCard isDetailsPage={false} airport={airport} />
          </div>
          <EventManagementCard airport={airport} pathname="/home" />
        </>
      </Stack>
      <FlyoutMenu></FlyoutMenu>
    </>
  );
};
