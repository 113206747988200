import { mergeStyleSets } from "@fluentui/react";
import { Image } from "@fluentui/react-northstar";
import { IHOPSummaryItem } from "../../../src/models/health-of-operations/IHOPSummary";
import ArrowIcon from "../../components/common/images/right-arrow.svg";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { HOPAlertIcon } from "../health-of-operations/HOPAlertIcon";

export const AirportStatusSummary: React.FunctionComponent<
  IAirportSummarySectionProps
> = (props: IAirportSummarySectionProps) => {
  return (
    <div className="ms-Grid" data-testid="airport-status-summary">
      {props.summaryData && props.summaryData.length > 0 && (
        <div className="ms-Grid-row amd-container-vcenter">
          {props.summaryData?.map(
            (currentValue: IHOPSummaryItem, i: number) => {
              const kpiStatus = currentValue.kpis.filter(
                (item) => item.key === "all"
              )[0].status;
              const performance1 = currentValue.kpis.filter(
                (item) => item.key === "all"
              )[0].performance;
              return (
                <div
                  data-testid="airport-item"
                  key={CommonHelper.getGuid()}
                  className={` ms-Grid-col ms-md4 w-100 ${
                    contentStyles.airportSummaryFont
                  } ${i > 0 ? "divider-v-ltgrey" : ""} `}
                >
                  <div className="amd-container-vcenter">
                    <span
                      className={`${contentStyles.inlineText} ${
                        kpiStatus === "poor"
                          ? `${contentStyles.textColor} fw-600`
                          : ""
                      }`}
                    >
                      {currentValue.label}
                    </span>
                    <span>
                      <AmdButton
                        type={AmdButtonType.ICON_BUTTON}
                        testId="otp-right-arrow"
                        className={`d-flex`}
                      >
                        <Image
                          className={`ml-1 ${contentStyles.arrowPostion}`}
                          src={ArrowIcon}
                        />
                      </AmdButton>
                    </span>
                  </div>

                  <div className="amd-container-vcenter">
                    <HOPAlertIcon status={kpiStatus} />
                    <span
                      className={`ml-1 fs-18 ${
                        kpiStatus === "poor"
                          ? `${contentStyles.textColor} fw-600`
                          : ""
                      } ${
                        kpiStatus === "moderate"
                          ? `${contentStyles.textColor} fw-400`
                          : ""
                      }`}
                    >
                      {performance1}%
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

const contentStyles = mergeStyleSets({
  airportSummaryFont: {
    font: "Sorce Sans Pro",
    fontSize: "13px",
  },
  arrowPostion: {
    height: "12px",
    width: "12px",
    color: "#005eb8",
    fontWeight: "600",
  },
  inlineText: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  textColor: {
    color: "#c60000",
  },
});
export interface IAirportSummarySectionProps {
  summaryData?: IHOPSummaryItem[];
}
