import { useEffect, useState } from "react";
import { IOTPTabSummary } from "../../../../models/flights/IOTPSummary";
import { OTPHelper } from "../../../../utils/flights/OTPHelper";

const useOTPTabsEffect = (msalInstance: any, airport: string) => {
  const [otpTabsSummary, setOtpTabsSummary] = useState<IOTPTabSummary[]>([]);
  const [otpTabs, setOtpTabs] = useState<any[]>([]);
  useEffect(() => {
    let isMounted = true;

    OTPHelper.getOTPTabsSummary(msalInstance).then((response: any) => {
      if (isMounted && response && response.data) {
        OTPHelper.getOTPSummary(msalInstance, airport).then(
          (otpResponse: any) => {
            //To be removed - workaround for PTE
            if (otpResponse && otpResponse.data) {
              OTPHelper.updateTabSummary(response.data, otpResponse.data);
              setOtpTabsSummary(response.data);
              setOtpTabs(OTPHelper.getOTPTabs(response.data));
            }
          }
        );
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return { otpTabsSummary, otpTabs };
};

export default useOTPTabsEffect;
