import * as React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { AmdButton } from "../form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../icons/arrow/arrow";
import { ArrowDirection } from "../icons/arrow/arrowDirection";
import { getSliderStyles } from "./AmdSliderStyles";

interface IAmdSliderProps {
  width?: number;
  height?: number;
  children?: React.ReactElement[];
  cardCounter?: number;
}

const AmdSlider: React.FunctionComponent<IAmdSliderProps> = ({
  width = 386,
  height = 362,
  children = [],
  cardCounter = 1,
}) => {
  const [cardRefs, setCardRefs] = React.useState<any>([]);
  const [currentCardViewIndex, setCurrentCardViewIndex] = React.useState(0);
  const [updateComponent, setComponentUpdate] = React.useState<boolean>(false);
  const sliderStyles = getSliderStyles(width, height);
  const viewBoxRef: any = React.useRef(null);
  const contentRef: any = React.useRef(null);

  React.useEffect(() => {
    if (children.length > 0 && !cardRefs[0]?.current) {
      setCardRefs(children.map((_x) => React.createRef()));
      setComponentUpdate(!updateComponent);
    }
  }, [children]);

  const handleSliderNavigation = (direction = "right") => {
    const viewIndex = CommonHelper.getSliderNavViewIndex(
      viewBoxRef,
      children.length,
      cardCounter,
      currentCardViewIndex,
      direction,
      width
    );
    setCurrentCardViewIndex(viewIndex);
  };

  const updateStateOnScroll = () => {
    setComponentUpdate(!updateComponent);
  };

  return (
    <>
      <header className="amd-section-shadow__header">
        <span>QUICK INFORMATION</span>
        <span className="float-r">
          <AmdButton
            type={AmdButtonType.DEFAULT}
            onClick={() => handleSliderNavigation("left")}
            disabled={CommonHelper.sliderIsScrolledToLeft(viewBoxRef)}
          >
            <AmdArrow direction={ArrowDirection.LEFT} color="#666" />
          </AmdButton>
          <AmdButton
            type={AmdButtonType.DEFAULT}
            className="ml-1"
            onClick={() => handleSliderNavigation("right")}
            disabled={CommonHelper.sliderIsScrolledToRight(
              children.length,
              viewBoxRef,
              contentRef
            )}
          >
            <AmdArrow direction={ArrowDirection.RIGHT} color="#666" />
          </AmdButton>
        </span>
      </header>
      <div
        className={sliderStyles.sliderViewBox}
        ref={viewBoxRef}
        onScroll={updateStateOnScroll}
      >
        <div className="d-flex" ref={contentRef}>
          {children?.map((cardItem: any, index: number) => {
            const cardItemKey = `slider_item_${index}`;
            return (
              <section
                key={cardItemKey}
                ref={cardRefs[index]}
                className={`amd-section--br ${sliderStyles.cardDimension}`}
              >
                {cardItem}
              </section>
            );
          })}
        </div>
      </div>
    </>
  );
};

AmdSlider.defaultProps = {
  children: [],
  width: 386,
  height: 362,
  cardCounter: 1,
};

export default AmdSlider;
