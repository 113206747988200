import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdEdit: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="edit-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9519 1.0481C17.5543 -0.349424 15.2885 -0.349357 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204105 19.0777C-0.0399142 19.3311 0.0355221 19.5976 0.219685 19.7817C0.403848 19.9659 0.670367 20.0413 0.92373 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM13 4.06084L15.9394 7.00018L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdEdit.defaultProps = {
  color: "#005EB8",
};
