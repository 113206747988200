enum TagPickerType {
  Roles = "roles",
  Organizations = "organizations",
  Responsibilities = "responsibilities",
  Terminals = "terminals",
  Users = "users",
  Channel = "channels",
  Kpi = "kpi",
}

const TagPickerEnum = {
  TagPickerType,
};

export default TagPickerEnum;
