import { mergeStyleSets } from "@fluentui/react";
import { DonutChart, IChartProps } from "@fluentui/react-charting";
import { IResourceData } from "../../../../models/resource-allocation/IResourceData";
import ChartHelper from "../../../../utils/common/ChartHelper";
import { NoDataCard } from "../../no-data-card/NoDataCard";
import { Legend } from "./Legend";
import { LegendPosition } from "./LegendPositions";

export const AmdDonutChart: React.FunctionComponent<IAmdDonutChart> = ({
  resourceData,
  navigateUrl,
  showPercent,
  noFlightsMessage,
  width,
  height,
  radius,
  hideLegend = false,
  hideValueInsideDonut,
  showCustomLegend,
  legendPosition = LegendPosition.LEFT,
  legendData = [],
  title = null,
  titleAlignment = "left",
  chartHeader = null,
  chartAlignment = "left",
  chartLegendAlignment = "left",
  containerWidth = "auto",
  donutContainerWidth = "100%",
  donutContainerHeight = "100%",
  testId = "donutChart",
  wrapperClassName = "",
}) => {
  const result = ChartHelper.getChartPoints(resourceData);
  const data: IChartProps = {
    chartData: result?.chartPoints,
  };

  const chartStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: legendPosition === LegendPosition.LEFT ? "row" : "column",
      justifyContent: chartLegendAlignment === "left" ? "flex-start" : "center",
      width: containerWidth,
    },
    title: {
      fontWeight: 600,
      fontSize: 13,
      color: "#333333",
      textAlign: titleAlignment,
    },
  });

  const donutStyles = mergeStyleSets({
    root: {
      width: donutContainerWidth,
      alignItems: chartAlignment === "left" ? "flex-start" : "center",
      height: donutContainerHeight,
    },
  });

  return (
    <>
      <div className={wrapperClassName}>
        {resourceData && resourceData.length > 0 && title && (
          <div className={`${chartStyles.title} chart-title`}>{title}</div>
        )}
        {resourceData && resourceData.length > 0 && chartHeader && (
          <>{chartHeader}</>
        )}
        <div className={chartStyles.container} data-testid={testId}>
          {resourceData && resourceData.length === 0 ? (
            <NoDataCard message={noFlightsMessage} />
          ) : (
            <>
              <DonutChart
                culture={window.navigator.language}
                data={data}
                innerRadius={radius ? radius : 60}
                legendsOverflowText={"overflow Items"}
                hideLegend={hideLegend}
                height={height ? height : 152}
                width={width ? width : 100}
                svgProps={{
                  width: width,
                  height: height,
                }}
                hideLabels={hideLegend}
                valueInsideDonut={ChartHelper.getValueInsideDonut(
                  result,
                  showPercent,
                  hideValueInsideDonut
                )}
                href={navigateUrl}
                styles={donutStyles}
              />
              {showCustomLegend && <Legend legendData={legendData} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export interface IAmdDonutChart {
  resourceData?: IResourceData[];
  navigateUrl?: string;
  showPercent?: boolean;
  noFlightsMessage: string;
  width?: number;
  height?: number;
  radius?: number;
  hideLegend?: boolean;
  hideValueInsideDonut?: boolean;
  showCustomLegend?: boolean;
  legendPosition?: string;
  legendData?: any[];
  title?: string;
  titleAlignment?: "left" | "center";
  chartHeader?: React.ReactElement;
  chartAlignment?: "left" | "center";
  chartLegendAlignment?: "left" | "center";
  containerWidth?: string;
  donutContainerWidth?: string;
  donutContainerHeight?: string;
  testId?: string;
  wrapperClassName?: string;
}
