import { mergeStyles } from "@fluentui/react";
import DateHelper from "../../../utils/common/DateHelper";
import { AmdButton } from "../form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../icons/arrow/arrow";
import { AmdClose } from "../icons/close/Close";

import { Link } from "@fluentui/react";
import PageEnum from "../../../enum/PageEnum";
import { CommonHelper } from "../../../utils/common/CommonHelper";

export interface IAmdToastItemProps {
  handleAlertClick: any;
  index: number;
  handleExpansion: any;
  className: string;
  alertItem: any;
  isFirstItem: boolean;
  expanded: boolean;
  topValue: string;
}

export const AmdToastItem: React.FunctionComponent<IAmdToastItemProps> = (
  props: IAmdToastItemProps
) => {
  return (
    <div
      key={`alert_${props.index}`}
      className={props.className}
      onClick={props.handleExpansion}
      id={`toast_alert_${props.index}`}
      style={{ top: `${props.topValue}` }}
      title={props.alertItem.description}
    >
      <span className={descriptionStyles}>
        [{props.alertItem.airportCode}] - <b>{props.alertItem.title}</b>:{" "}
        {props.alertItem.body}
      </span>
      <span className="float-r amd-container-vcenter ml-1">
        <span className={timestampStyles}>
          {DateHelper.getFormattedDateTime(
            props.alertItem.timestamp,
            "YYYY-MM-DD HH:mm:ss"
          )}
        </span>

        {/*Create Event Link */}
        <span className={`${createEventStyles} ml-1`}>
          <Link
            href={`/${PageEnum.PageUrl.EventManagement}?title=${props.alertItem.title}&descr=${props.alertItem.body}`}
            className={`text-d-none`}
            onClick={() => {
              window.sessionStorage.setItem("isNewEventClicked", "true");
            }}
          >
            Create Event
          </Link>
        </span>

        {(props.isFirstItem || props.expanded) && (
          <a
            className={`mr-2 link-br`}
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.handleAlertClick(props.index, props.alertItem, true)
            }
          >
            <AmdArrow color="#212121" />
          </a>
        )}
        <AmdButton
          type={AmdButtonType.ICON_BUTTON}
          onClick={() =>
            props.handleAlertClick(props.index, props.alertItem, false)
          }
        >
          <AmdClose color="#C4314B" />
        </AmdButton>
      </span>
    </div>
  );
};

const descriptionStyles = mergeStyles({
  width: "calc(100% - 72px)",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const timestampStyles = mergeStyles({
  width: "150px",
  fontSize: "12px",
});

const createEventStyles = mergeStyles({
  width: "80%",
});
