import { AnimationClassNames } from "@fluentui/react";
import { LineChart } from "@fluentui/react-charting";
import React from "react";
import { useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import { AmdDeicing } from "../../common/icons/deicing/Deicing";

export const WeatherDisruptionGraph: React.FunctionComponent =
  (): React.JSX.Element | null => {
    const weatherDisruptionState = useAppSelector((state: any) =>
      getWeatherDisruptionState(state)
    );

    const {
      selectedDisruptionKPI,
      selectedKpi: selectedKpiTitle,
      selectedKpiId,
    } = weatherDisruptionState;

    const { weatherDisruptionChartData, allowMultipleShapes, width, height } =
      selectedDisruptionKPI;

    const title = `${selectedKpiTitle} trend`;

    if (!weatherDisruptionChartData?.lineChartData?.length) {
      return null;
    }

    return (
      <div
        data-testid="weather-disruption-graph"
        className={`ms-Grid-row ${
          selectedKpiId && AnimationClassNames.slideDownIn20
        }`}
      >
        <div className="mt-2 ml-1 fw-300 fs-20 mb-1">
          <AmdDeicing
            className="ml-1"
            width="40"
            height="35"
            color="#1890F140"
          />
          {title}
        </div>
        <LineChart
          data={weatherDisruptionChartData}
          yMinValue={0}
          yMaxValue={100}
          tickFormat={"%H:%M"}
          allowMultipleShapesForPoints={allowMultipleShapes}
          height={height}
          width={width}
          hideLegend={false}
          xAxisTickCount={7}
          yAxisTickCount={10}
        />
      </div>
    );
  };

export default WeatherDisruptionGraph;
