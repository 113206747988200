import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdShowGraph: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="show-graph-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 9C7.875 9 7 8.125 7 7C7 7 7 6.96875 7 6.9375C7.15625 6.96875 7.3125 7 7.5 7C8.3125 7 8.96875 6.34375 8.96875 5.53125C8.96875 5.34375 8.9375 5.1875 8.90625 5.03125C8.9375 5.03125 8.96875 5 9 5C10.0938 5 11 5.90625 11 7C11 8.125 10.0938 9 9 9ZM17.875 7.5625C17.9375 7.6875 17.9688 7.84375 17.9688 8.03125C17.9688 8.1875 17.9375 8.34375 17.875 8.46875C16.1875 11.7812 12.8125 14 9 14C5.15625 14 1.78125 11.7812 0.09375 8.46875C0.03125 8.34375 0 8.1875 0 8C0 7.84375 0.03125 7.6875 0.09375 7.5625C1.78125 4.25 5.15625 2 9 2C12.8125 2 16.1875 4.25 17.875 7.5625ZM9 3C6.78125 3.03125 5 4.8125 5 7C5 9.21875 6.78125 11 9 11C11.1875 11 13 9.21875 13 7C13 4.8125 11.1875 3 9 3ZM9 13C12.3438 13 15.4062 11.0938 17 8C16.0938 6.28125 14.6875 4.84375 12.9375 3.96875C13.5938 4.8125 14 5.875 14 7C14 9.78125 11.75 12 9 12C6.21875 12 4 9.78125 4 7C4 5.875 4.375 4.8125 5.03125 3.96875C3.28125 4.84375 1.875 6.28125 1 8C2.5625 11.0938 5.625 13 9 13Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdShowGraph.defaultProps = {
  color: "#005EB8",
};
