import {
  IHOPKpi,
  IHOPSummaryItem,
} from "../../models/health-of-operations/IHOPSummary";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { HOPAlertIcon } from "../health-of-operations/HOPAlertIcon";
import { HOPOTPKPIIcon } from "../health-of-operations/HOPOTPKPIIcon";
export const AirportOTPSection: React.FunctionComponent<IHOPOTPSectionProps> = (
  props: IHOPOTPSectionProps
) => {
  return (
    <div
      className="d-flex mt-sm-1 jc-spacebtwn"
      data-testid="airport-otp-section"
      tabIndex={0}
    >
      {props.otpData?.kpis.map((kpi: IHOPKpi) => {
        return (
          <div className="amd-container-vcenter ml-2 hop-otp-kpi" key={kpi.key}>
            <HOPAlertIcon status={kpi.status} />
            <HOPOTPKPIIcon kpiKey={kpi.key} kpiName={props.otpData?.label} />
            <span
              className={`${HOPHelper.getLegendTextColor(
                kpi.status
              )} fw-700 ml-2`}
            >
              {kpi.performance}%
            </span>
          </div>
        );
      })}
    </div>
  );
};
export interface IHOPOTPSectionProps {
  otpData?: IHOPSummaryItem;
}
