import { mergeStyleSets } from "@fluentui/react";
import * as React from "react";


export interface IAmdMessageProps {
  children?: React.ReactElement;
  className?: string;
  key?: string;
  width?: string | number;
}

export const AmdMessage: React.FunctionComponent<IAmdMessageProps> = (
  props: IAmdMessageProps
) => {
  const messageStyles = mergeStyleSets({
    container: {
      padding: "15px 10px",
      background: 'rgba(155, 202, 235, 0.5)',
      color: '#004485',
      display: 'flex',
      width: props.width
    },
  })
  return (
    <div className={`${messageStyles.container} ${props.className}`}>
      {props.children}
    </div>
  );
};

AmdMessage.defaultProps = {
  className: "",
  width: 'auto',
  key: ''
};
