export const operatorOptions = {
  number: [
    { key: "greater", text: ">" },
    { key: "less", text: "<" },
    { key: "greater-equal", text: ">=" },
    { key: "lesser-equal", text: "<=" },
  ],
  common: [
    { key: "in", text: "in" },
    { key: "equal", text: "=" },
  ],
};
