import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { IFlightScheduleFilters } from "../../../models/flights/IFlightScheduleFilters";
import { FlightListHelper } from "../../../utils/flights/FlightListHelper";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
import useFlightListSummaryEffect from "./hooks/useFlightListSummaryEffect";

export const FlightDetailList: React.FunctionComponent<IFlightDetailList> = ({
  flightListState,
  selectedType,
  viewType,
  getFlightTotal,
  selectedResource,
  isOTPView = false,
  airport,
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rowItems, setRowItems] = useState<any[]>([]);
  const msalInstance = useMsal().instance as PublicClientApplication;

  const { flightListData, error, isLoading } = useFlightListSummaryEffect(
    msalInstance,
    flightListState,
    viewType,
    airport
  );

  useEffect(() => {
    if (getFlightTotal !== undefined) {
      getFlightTotal(flightListData.total);
    }
    const colResponse = FlightListHelper.getFlightListColumns(
      selectedType,
      viewType,
      selectedResource
    );
    const updatedCols = colResponse.map((col) => {
      return {
        key: col.fieldName,
        ...col,
      };
    });
    setColumns(updatedCols);
    const { arrivalData, departureData, mixedData } = flightListData;
    const updatedArrivalData = arrivalData.map((flight) => {
      return {
        key: `${flight.flightNumber}-${flight.airline}-${flight.origin}`,
        ...flight,
      };
    });
    const updatedDepartureData = departureData.map((flight) => {
      return {
        key: `${flight.flightNumber}-${flight.airline}-${flight.destination}`,
        ...flight,
      };
    });
    const updatedMixedData = mixedData.map((flight) => {
      return {
        key: `${flight.arrivalFlightNumber}-${flight.departureFlightNumber}-${flight.registration}`,
        ...flight,
      };
    });

    const updatedFlightListData = {
      ...flightListData,
      arrivalData: updatedArrivalData,
      departureData: updatedDepartureData,
      mixedData: updatedMixedData,
    };

    setRowItems(
      FlightListHelper.getFlightListRows(selectedType, viewType, {
        arrivalData: updatedFlightListData.arrivalData,
        departureData: updatedFlightListData.departureData,
        mixedData: updatedFlightListData.mixedData,
      })
    );
  }, [flightListData]);

  return (
    <div data-testid="flightDetailList">
      {error && <NoDataCard message="No Flights to Display" />}

      {isLoading && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "40vh" }}
        />
      )}
      {!error &&
        !isLoading &&
        (rowItems?.length > 0 && columns?.length > 0 ? (
          <AmdDataGrid
            showActionButtons={false}
            rowItems={rowItems}
            columns={columns}
            infoHeaders={["Delay Code", "Delay Duration"]}
            selectedType={selectedType}
            viewType={viewType}
          />
        ) : (
          <NoDataCard message="No Flights to Display" />
        ))}
    </div>
  );
};

export interface IFlightDetailList {
  flightListState: IFlightScheduleFilters;
  selectedType: string;
  viewType: string;
  getFlightTotal?: any;
  selectedResource?: string;
  isOTPView?: boolean;
  airport: string;
  defaultSortField?: string;
}
