import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdGraph: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="graph-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.19922 5.85156L2.89844 5.55078C2.73438 5.38672 2.73438 5.08594 2.89844 4.92188L4.92188 2.89844C5.08594 2.73438 5.38672 2.73438 5.55078 2.89844L7.875 5.22266L10.2539 2.81641L9.46094 1.99609C9.02344 1.58594 9.32422 0.875 9.92578 0.875H12.6875C12.9062 0.875 13.125 1.09375 13.125 1.3125V4.07422C13.125 4.67578 12.4141 4.97656 12.0039 4.53906L11.1836 3.74609L8.17578 6.75391C8.01172 6.91797 7.71094 6.91797 7.54688 6.75391L5.25 4.42969L3.82812 5.85156C3.66406 6.01562 3.36328 6.01562 3.19922 5.85156ZM13.5625 9.1875C13.7812 9.1875 14 9.40625 14 9.625V10.0625C14 10.3086 13.7812 10.5 13.5625 10.5H0.875C0.382812 10.5 0 10.1172 0 9.625V0.4375C0 0.21875 0.191406 0 0.4375 0H0.875C1.09375 0 1.3125 0.21875 1.3125 0.4375V9.1875H13.5625Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdGraph.defaultProps = {
  color: "#005EB8",
};
