import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError, CancelTokenSource } from "axios";
import React, { useEffect } from "react";
import { AuthHelper } from "../utils/api/AuthHelper";

export type FetchData<T> = {
  data: T | undefined;
  error: string | undefined;
  isLoading: boolean;
};

type methods = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";
const contentType = "application/json;charset=UTF-8";

interface ApiCallProps {
  url: string | string[];
  refresh?: boolean;
  requestType?: methods;
  payload?: any;
  dependencies?: any[];
}

const validateUrl = (url: string | string[]): boolean => {
  if (
    (Array.isArray(url) && url.length === 0) ||
    (typeof url === "string" && url === "")
  ) {
    return false;
  }
  return true;
};

/* This is a custom hook that fetches data from an API with the given URL.
 It uses the MSAL instance to get the access token and then makes a GET request to the API with the token in the header.
 It returns the data, error, and loading states. */

export const useApiCall = <T = unknown>({
  url,
  refresh = true,
  requestType = "get",
  payload = "",
  dependencies = [],
}: ApiCallProps): FetchData<T> => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [data, setData] = React.useState<T | undefined>();
  const [error, setError] = React.useState<string | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fetchApiResults = async (source: any, headers: any, payloadArg: any) => {
    let result;
    if (Array.isArray(url)) {
      const requests = url.map((url) =>
        axios[requestType](url, {
          cancelToken: source.token,
          headers,
        })
      );
      result = (await Promise.all(requests)) as T;
    } else {
      const response =
        requestType === "get"
          ? await axios[requestType](url, {
              cancelToken: source.token,
              headers,
            })
          : await axios[requestType](url, JSON.stringify(payloadArg), {
              cancelToken: source.token,
              headers,
            });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error(
          String(`Request failed with status code ${response.status}`)
        );
      }
      result = response.data as T;
    }

    return result;
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const validUrl = validateUrl(url);

    const apiCallHandler = async () => {
      try {
        setIsLoading(true);

        const accessToken = await AuthHelper.getAccessToken(msalInstance);
        if (accessToken === "") {
          throw new Error(String("Access token cannot be empty!"));
        }
        const headers = {
          "x-api-key": process.env.REACT_APP_APIM_KEY,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": contentType,
        };

        const result = await fetchApiResults(source, headers, payload);
        setData(result);
        setError(undefined);
      } catch (err) {
        const errorResponse: AxiosError = err as AxiosError;
        //If API rquest is cancelled, do not set the Error
        if (!axios.isCancel(err)) {
          setError(errorResponse.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch the data if the refresh flag is true
    if (refresh && validUrl) {
      apiCallHandler();
    }

    return () => {
      source?.cancel("Request cancelled");
    };
  }, [refresh, JSON.stringify(dependencies), JSON.stringify(payload)]);

  // Return the data, error, and loading state
  return { isLoading, error, data };
};
