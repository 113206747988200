import { useEffect, useState } from "react";
import RESOURCE_ALLOCATION_FILTERS from "../../data/filter-panel/resource-allocation.json";
import RESOURCE_ALLOCATION_FILTERS_XYZ from "../../data/filter-panel/resource-allocation_XYZ.json";
import IFilters from "../../models/filters/IFilters";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import IResourceAvailability from "../../models/resource-allocation/IResourceAvailability";
import { getResourceAllocationFilters } from "../../store/resource-allocation-filter/ResourceAllocationFilterSelector";
import { resourceAllocationFilterActions } from "../../store/resource-allocation-filter/ResourceAllocationFilterSlice";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import FlightScheduleHelper from "../../utils/flights/FlightScheduleHelper";
import { ResourceHelper } from "../../utils/resource/ResourceHelper";
import { ResourceAllocationKPICard } from "./ResourceAllocationKPICard";

const defaultHourValue = 12;
export const ResourceAllocationKPI: React.FunctionComponent<
  IResourceAllocationKPIProps
> = ({
  legType,
  resourceAvailabilityData,
  showList = false,
  resourceAllocationData,
  onSelectTerminal,
  selectedResourceTab,
  onSelectRange,
  range,
  airport,
}) => {
  const [selectedType, setSelectedType] = useState<string>(legType);

  useEffect(() => {
    onLegTypeChange(legType);
  }, [legType]);

  useEffect(() => {
    onResourceTabChange(selectedResourceTab);
  }, [selectedResourceTab]);

  const localStorageFiltersKey = `${airport}paxFilters`;
  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem(localStorageFiltersKey);
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }

  const setResourceAllocationFilters = () => {
    if (filters && filters.length > 0) {
      filters = ResourceHelper.getResourceSpecificFilters(
        selectedResourceTab,
        filters
      );

      dispatch(
        resourceAllocationFilterActions.setResourceAllocationFilters({
          airport: airport,
          filter: filters,
        })
      );
      const selectedItems = FlightScheduleHelper.getSelectedItems(filters);
      const flightListState =
        FlightScheduleHelper.getFlightlistState(selectedItems);
      onFiltersApplied(flightListState);
    } else {
      const airportResourceFilter =
        airport === "XYZ"
          ? RESOURCE_ALLOCATION_FILTERS_XYZ.data
          : RESOURCE_ALLOCATION_FILTERS.data;

      const resourceFilters = ResourceHelper.getResourceSpecificFilters(
        selectedResourceTab,
        airportResourceFilter
      );

      dispatch(
        resourceAllocationFilterActions.setResourceAllocationFilters({
          airport: airport,
          filter: resourceFilters,
        })
      );
    }
  };

  useEffect(() => {
    setResourceAllocationFilters();
  }, [dispatch]);

  const resourceFilters = useAppSelector((state) =>
    getResourceAllocationFilters(state)
  );

  const [flightListState, setFlightListState] =
    useState<IFlightScheduleFilters>(
      FlightScheduleHelper.getDefaultFlightListState(
        airport,
        defaultHourValue,
        selectedResourceTab
      )
    );

  const onResourceTabChange = (_selectedTab: string) => {
    const stateObj = { ...flightListState };
    stateObj.viewType = _selectedTab;
    setFlightListState(stateObj);
    const updatedItems = ResourceHelper.updateResourceFiltersDisplay(
      resourceFilters,
      selectedType,
      _selectedTab
    );
    onUpdateFilter(updatedItems);
  };

  const onLegTypeChange = (_selectedType: string) => {
    setSelectedType(_selectedType);
    const stateObj = { ...flightListState };
    stateObj.flightType = _selectedType;
    setFlightListState(stateObj);
    const updatedItems = ResourceHelper.updateResourceFiltersDisplay(
      resourceFilters,
      _selectedType,
      selectedResourceTab
    );
    onUpdateFilter(updatedItems);
  };

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(
      resourceAllocationFilterActions.setResourceAllocationFilters({
        airport: airport,
        filter: updatedItems,
      })
    );
  };

  const onFiltersApplied = (_filters: IFlightScheduleFilters) => {
    let stateObj = FlightScheduleHelper.getDefaultFlightListState(
      airport,
      defaultHourValue,
      selectedResourceTab
    );
    stateObj = FlightScheduleHelper.onFlightScheduleFiltersApplied(
      stateObj,
      _filters,
      airport
    );
    setFlightListState(stateObj);
  };

  const onLegendClick = (_selectedLegend: string) => {
    const legend = ResourceHelper.getSelectedLegendValue(
      _selectedLegend,
      selectedResourceTab
    );
    setFlightListState(
      FlightScheduleHelper.handleLegendClick(
        legend.value,
        flightListState,
        legend.key
      )
    );
  };

  const onResetGraphicClick = () => {
    setFlightListState(
      FlightScheduleHelper.handleResetGraphicClick(
        airport,
        defaultHourValue,
        flightListState,
        ResourceHelper.getLegendKey(selectedResourceTab),
        selectedResourceTab
      )
    );
  };

  const onDataPointClick = (x: string, y: string, _selectedLegend: string) => {
    const legend = ResourceHelper.getSelectedLegendValue(
      _selectedLegend,
      selectedResourceTab
    );
    setFlightListState(
      FlightScheduleHelper.handleDataPointClick(
        x,
        y,
        legend.value,
        flightListState,
        legend.key
      )
    );
  };

  return (
    <div data-testid="ResourceAllocationKPI">
      <ResourceAllocationKPICard
        resourceAvailabilityData={resourceAvailabilityData}
        resourceAllocationData={resourceAllocationData}
        showList={showList}
        legType={legType}
        selectedResourceTab={selectedResourceTab}
        onFiltersApplied={onFiltersApplied}
        onUpdateFilter={onUpdateFilter}
        selectedType={selectedType}
        resourceFilters={resourceFilters}
        flightListState={flightListState}
        onResetGraphicClick={onResetGraphicClick}
        onDataPointClick={onDataPointClick}
        onSelectTerminal={onSelectTerminal}
        onSelectRange={onSelectRange}
        onLegendClick={onLegendClick}
        range={range}
        airport={airport}
      />
    </div>
  );
};

export interface IResourceAllocationKPIProps {
  showList?: boolean;
  legType: string;
  resourceAvailabilityData?: IResourceAvailability[];
  resourceAllocationData?: any[];
  onSelectTerminal: any;
  selectedResourceTab: string;
  onSelectRange: any;
  range?: number;
  airport: string;
}
