import { Input } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import IDimension, { IThreshold } from "../../models/admin/IDimension";
import { HOP_RANGE_COLORS } from "../../utils/common/Constants";
import { AmdAmber } from "../common/icons/amber/Amber";
import { AmdGreen } from "../common/icons/green/Green";
import { AmdRed } from "../common/icons/red/red";
import { MultiProgressBar } from "../common/multi-progress/MultiProgressBar";

export const ThresholdInput: React.FunctionComponent<IThresholdInputProps> = (
  props: IThresholdInputProps
) => {
  const [newDimensionDetails, setNewDimensionDetails] = useState<IDimension>();
  useEffect(() => {
    setNewDimensionDetails({
      ...props.selectedDimension,
    });
  }, [props.selectedDimension]);

  return (
    <>
      {newDimensionDetails?.thresholds?.map((t: IThreshold) => {
        return (
          <div
            key={t.type}
            data-testid={`${t.type}-threshold`}
            className={`${t.type}-threshold`}
          >
            <div className="mt-1 mb-2">
              <div className="mb-2 fs-20 fw-400 fc--gray900">{t.label}</div>
            </div>
            <div className="mt-1 mb-2">
              <p>
                {t.prefixDesc}
                {t.earlyBuffer && (
                  <Input
                    type="number"
                    placeholder=""
                    style={{ width: 45, padding: 0 }}
                    value={t.earlyBuffer?.toString()}
                    aria-label="inline"
                    data-testid={`${t.type}-early-buffer`}
                    onChange={(_e, target) => {
                      t.earlyBuffer = parseInt(target.value);
                      props.onChangeThresholdValue(newDimensionDetails);
                    }}
                  />
                )}

                {t.earlyBuffer && t.suffixDesc}
                {t.lateBuffer ? (
                  <>
                    <Input
                      type="number"
                      placeholder=""
                      style={{ width: 45, padding: 0 }}
                      value={t.lateBuffer?.toString()}
                      aria-label="inline"
                      data-testid={`${t.type}-late-buffer`}
                      onChange={(_e, target) => {
                        t.lateBuffer = parseInt(target.value);
                        props.onChangeThresholdValue(newDimensionDetails);
                      }}
                    />
                    {t.suffixDesc1}
                  </>
                ) : null}
                {!t.earlyBuffer && t.suffixDesc}
              </p>
            </div>
            <div className="mt-1 mb-2">
              <div className="mb-2 fs-20 fw-400 fc--gray900">
                {`${newDimensionDetails.label} thresholds`}
              </div>
            </div>
            <MultiProgressBar
              poor={HOP_RANGE_COLORS.poor}
              moderate={HOP_RANGE_COLORS.moderate}
              good={HOP_RANGE_COLORS.good}
              barDivisionValue1={t.moderate.min}
              barDivisionValue2={t.green.min}
              unit="%"
              format={["poor", "good"]}
            />

            <div className="d-flex jc-spacebtwn d-flex--wrap mt-1 mb-2">
              <div className="amd-container-vcenter mb-1">
                <span className="d-flex jc-center">
                  <AmdRed />
                </span>

                <span className="ml-1">Poor :</span>
                <span className="ml-1">Less than</span>
                <span className="ml-1">
                  <Input
                    type="number"
                    placeholder=""
                    style={{ width: 45, padding: 0 }}
                    value={t.poor.max.toString()}
                    aria-label="inline"
                    data-testid={`${t.type}-amberToRed`}
                    onChange={(_e, target) => {
                      const val = parseInt(target.value);
                      t.moderate.min = val + 1;
                      t.poor.max = val;
                      props.onChangeThresholdValue(newDimensionDetails);
                    }}
                  />
                </span>
                <span className="ml-1">%</span>
              </div>

              <div className="amd-container-vcenter mb-1">
                <span className="d-flex jc-center">
                  <AmdAmber />
                </span>

                <span className="ml-1 ">Moderate :</span>
                <span className="ml-1">
                  <Input
                    placeholder=""
                    type="number"
                    value={t.moderate?.min.toString()}
                    style={{ width: 45, padding: 0 }}
                    aria-label="inline"
                    disabled
                  />
                </span>
                <span className="ml-1">to</span>
                <span className="ml-1">
                  <Input
                    placeholder=""
                    style={{ width: 45, padding: 0 }}
                    type="number"
                    value={t.moderate.max.toString()}
                    aria-label="inline"
                    data-testid={`${t.type}-greenToAmber`}
                    onChange={(_e, target) => {
                      const val = parseInt(target.value);
                      t.green.min = val + 1;
                      t.moderate.max = val;
                      props.onChangeThresholdValue(newDimensionDetails);
                    }}
                  />
                </span>
                <span className="ml-1">%</span>
              </div>
              <div className="amd-container-vcenter mb-1">
                <span className="d-flex jc-center">
                  <AmdGreen />
                </span>

                <span className="ml-1">Green :</span>
                <span className="ml-1">Greater than</span>
                <span className="ml-1">
                  <Input
                    placeholder=""
                    type="number"
                    style={{ width: 45, padding: 0 }}
                    value={t.green?.min.toString()}
                    aria-label="inline"
                    disabled
                  />
                </span>

                <span className="ml-1">%</span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export interface IThresholdInputProps {
  selectedDimension: IDimension;
  onChangeThresholdValue: any;
}
