import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { EMPTY_STATE_MESSAGE } from "../../../utils/common/Constants";
import { RESOURCE_ALLOCATION_TABS } from "../../../utils/resource/ResourceConstants";
import { ResourceHelper } from "../../../utils/resource/ResourceHelper";
import { Carousel } from "../../common/carousel/Carousel";
import { AmdOptions } from "../../common/icons/options/Options";
import { AmdResource } from "../../common/icons/resource/Resource";
import { CarouselResourceItem } from "../CarouselResourceItem";

export const ResourcesAllocationCard: React.FunctionComponent<
  IResourcesAllocationCardProps
> = ({ airport }) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [carouselItems, setCarouselItems] = useState<any[] | undefined>([]);

  useEffect(() => {
    let isMounted = true;
    async function fetchResourceData() {
      const resources = Object.keys(RESOURCE_ALLOCATION_TABS);
      const requests: any[] = [];
      resources.forEach((key) => {
        const resource = (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[
          key
        ];
        resource.forEach((res: any) => {
          requests.push(
            ResourceHelper.getResourceAllocation(
              msalInstance,
              res.value,
              airport,
              res.subType
            )
          );
        });
      });
      const [baggageBelt, baggageChute, gate, stand, checkInCounter] =
        await Promise.all(requests);

      if (isMounted) {
        const resultObj = {
          baggage: [
            ResourceHelper.getResponseData(baggageBelt),
            ResourceHelper.getResponseData(baggageChute),
          ],
          gate: [ResourceHelper.getResponseData(gate)],
          stand: [ResourceHelper.getResponseData(stand)],
          checkInCounter: [ResourceHelper.getResponseData(checkInCounter)],
        };
        setCarouselData(resultObj);
      }
    }
    if (airport !== "") {
      fetchResourceData();
    }

    return () => {
      isMounted = false;
    };
  }, [airport]);

  const setCarouselData = (result: any) => {
    let carouselItems: any[] = [];
    if (result) {
      carouselItems = Object.keys(result)?.map((key: any) => ({
        key: key,
        id: key,
        content: (
          <CarouselResourceItem
            carouselResourceData={[
              {
                key: (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[
                  key
                ][0]?.title,
                data: (result as { [k in string]: any })[key][0]?.allocation,
              },
              {
                key:
                  (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[key][1]
                    ?.title ?? "",
                data: (result as { [k in string]: any })[key][1]?.allocation,
              },
            ]}
          />
        ),
        "aria-label": key,
      }));
      setCarouselItems(carouselItems);
    }
  };

  return (
    <div data-testid="resource-summary-card">
      <div className="amd-container-hspacebtwn pa-sm-2">
        <AmdResource
          width="18"
          height="18"
          color="#005EB8"
          viewBox="0 0 18 18"
        />
        <span className="fs-14 fw-600 fc--gray800">Resource Allocation</span>
        <AmdOptions />
      </div>
      <Carousel
        carouselItems={carouselItems ?? []}
        emptyStateMessage={EMPTY_STATE_MESSAGE.Resource}
      />
    </div>
  );
};
export default ResourcesAllocationCard;

export interface IResourcesAllocationCardProps {
  airport: string;
}
