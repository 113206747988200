import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdCloudRain: React.FunctionComponent<IAmdIconProps> = (props: IAmdIconProps) => {
  return (
    <svg width={props.width} height={props.height} viewBox={props.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9989 0C24.336 0 27.9309 4.19458 28.4537 9.26025L28.6136 9.26025C32.6918 9.26025 35.9978 12.5577 35.9978 16.6253C35.9978 20.6929 32.6918 23.9904 28.6136 23.9904L28.547 23.99L24.2479 31.1489C23.7502 31.9775 22.6752 32.2457 21.8466 31.7481C21.0641 31.2781 20.7814 30.2931 21.1716 29.4871L21.2475 29.3468L24.464 23.99H20.547L16.2479 31.1489C15.7502 31.9775 14.6752 32.2457 13.8466 31.7481C13.0641 31.2781 12.7814 30.2931 13.1716 29.4871L13.2475 29.3468L16.464 23.99H12.547L8.24788 31.1489C7.75024 31.9775 6.67516 32.2457 5.84662 31.7481C5.06411 31.2781 4.78137 30.2931 5.17159 29.4871L5.24747 29.3468L8.464 23.99L7.38416 23.9904C3.306 23.9904 0 20.6929 0 16.6253C0 12.5577 3.306 9.26025 7.38421 9.26025L7.54409 9.26025C8.06996 4.16128 11.6618 0 17.9989 0Z" fill={props.color}/>
    </svg>
  );
};

AmdCloudRain.defaultProps = {
  width: "36",
  height: "32",
  color: "#1890F1",
  viewBox: "0 0 36 32"
}
