import { DefaultButton, PrimaryButton, mergeStyleSets } from "@fluentui/react";
import { useEffect } from "react";
import { DialogFooter } from "@fluentui/react";
import { FormProvider, useForm } from "react-hook-form";
import TagPickerEnum from "../../../../enum/TagPickerEnum";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdTagPickControl } from "../../../common/form-controls/controlled/TagPicker/AmdTagPickControl";
import { AmdModal } from "../../../common/form-controls/uncontrolled/Modal/AmdModal";

export interface IEventAddMembersByGroupProps {
  hideDialog: boolean;
  selectedGroup?: any;
  selectedGroupId?: string;
  onModalPopupClose: any;
  onGroupAdded: any;
  airport: string;
}

export const EventAddMembersByGroup: React.FunctionComponent<
  IEventAddMembersByGroupProps
> = (props: IEventAddMembersByGroupProps) => {
  const methods = useForm();

  if (props.selectedGroup?.terminals?.length > 0) {
    props.selectedGroup.terminals =
      EventManagementHelper.mapTerminalsToKeyValuePair(
        props.selectedGroup.terminals
      );
  }

  useEffect(() => {
    // reset default field values
    methods.reset();
  }, []);

  useEffect(() => {
    if (props?.selectedGroup) {
      // set default values for form fields.
      const defaultFormFields = EventManagementHelper.getGroupFormDefaultValues(
        props.selectedGroupId,
        props.selectedGroup
      );
      methods.reset(defaultFormFields);
    }
  }, [props.selectedGroupId, props.selectedGroup]);

  const onGroupAddSuccess = (data: any) => {
    const groupData = EventManagementHelper.getAddedGroupDetails(
      props.selectedGroupId,
      data
    );
    props.onGroupAdded(groupData);
  };

  const onGroupAddFailure = (errors: any, _e: any) => {
    console.log(errors);
  };

  return (
    <AmdModal
      isOpen={!props.hideDialog}
      isBlocking={true}
      header="Add group"
      onClose={props.onModalPopupClose}
      containerClassName={contentStyles.container}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(event: any) => {
            event.stopPropagation();
            methods.handleSubmit(onGroupAddSuccess, onGroupAddFailure)(event);
          }}
          noValidate
        >
          <div className={contentStyles.body}>
            {tagPickerControlsJson.map((json: any) => {
              return (
                <AmdTagPickControl
                  className={contentStyles.tagPickerLabel}
                  key={json.label}
                  controlId={json.fieldName}
                  isMandatory={json.isMandatory}
                  tagLabel={json.label}
                  suggestionType={json.tagType}
                  airport={props.airport}
                  selectedItems={EventManagementHelper.getResponseTeamGroupTags(
                    props.selectedGroup
                      ? props.selectedGroup[json.fieldName]
                      : []
                  )}
                />
              );
            })}
            <DialogFooter>
              <DefaultButton
                onClick={() => {
                  props.onModalPopupClose();
                }}
                text="Cancel"
                className="mr-1"
              />
              <PrimaryButton type="submit" text="Add" />
            </DialogFooter>
          </div>
        </form>
      </FormProvider>
    </AmdModal>
  );
};

const tagPickerControlsJson = [
  {
    label: "Roles",
    fieldName: TagPickerEnum.TagPickerType.Roles,
    tagType: TagPickerEnum.TagPickerType.Roles,
    isMandatory: false,
  },
  {
    label: "Organizations",
    fieldName: TagPickerEnum.TagPickerType.Organizations,
    tagType: TagPickerEnum.TagPickerType.Organizations,
    isMandatory: false,
  },
  {
    label: "Responsibilities",
    fieldName: TagPickerEnum.TagPickerType.Responsibilities,
    tagType: TagPickerEnum.TagPickerType.Responsibilities,
    isMandatory: false,
  },
  {
    label: "Terminals",
    fieldName: TagPickerEnum.TagPickerType.Terminals,
    tagType: TagPickerEnum.TagPickerType.Terminals,
    isMandatory: false,
  },
];

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "80vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  tagPickerLabel: {
    fontWeight: "normal",
  },
});
