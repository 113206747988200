import { Dropdown, mergeStyleSets } from "@fluentui/react";
import React from "react";

interface IAmdDropdownProps {
  placeholder?: string;
  options: any[];
  onChange: any;
  className?: string;
  defaultSelectedKey: string;
  isMultiselect?: boolean;
  dropdownWidth?: any;
}

export const AmdDropdown: React.FunctionComponent<IAmdDropdownProps> = (
  props: IAmdDropdownProps
) => {
  return (
    <Dropdown
      multiSelect={props.isMultiselect}
      styles={!props.isMultiselect ? dropdownStyles1 : dropdownStyles2}
      placeholder={props.placeholder}
      defaultSelectedKey={props.defaultSelectedKey}
      options={props.options}
      onChange={props.onChange}
      className={props.className}
      dropdownWidth={props.dropdownWidth}
    />
  );
};

AmdDropdown.defaultProps = {
  className: "",
};

const dropdownStyles1 = mergeStyleSets({
  dropdown: {
    background: "#f2f2f2",
    height: 45,
    borderRadius: 3,
  },
  title: {
    background: "#f2f2f2",
    border: "none",
    height: 45,
    paddingTop: 5,
  },
  caretDownWrapper: {
    height: 45,
    top: 7,
  },
});
const dropdownStyles2 = mergeStyleSets({
  dropdown: {
    background: "#f2f2f2",
    height: 45,
    borderRadius: 3,
  },
  title: {
    background: "#f2f2f2",
    border: "none",
    height: 45,
    paddingTop: 5,
  },
  caretDownWrapper: {
    height: 45,
    top: 7,
  },
  dropdownItemSelected: {
    backgroundColor: "#9BCAEB",
  },
});
