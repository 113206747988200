import IPassengerSummary from "../../../models/pax/IPassengerSummary";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
export const PassengerSummaryDetails: React.FunctionComponent<
  IPassengerSummaryDetails
> = (props: IPassengerSummaryDetails) => {
  return props.passengerSummaryData && props.passengerSummaryData.length > 0 ? (
    <div
      className="amd-container-hspacebtwn pa-sm-2"
      data-testid="passenger-summary-details"
      style={{ height: 120 }}
    >
      {props.passengerSummaryData?.map(
        (p: IPassengerSummary, index: number) => {
          return (
            <dl
              className={`amd-list-desc amd-list-desc--w138 ${
                index === 0 ? "mt-0" : "ma-0"
              }`}
              key={p.type}
            >
              <div className="amd-list-desc__heading">
                {CommonHelper.toTitleCase(p.type)}
              </div>
              <div>
                <dt className="amd-list-desc__dt">Pax</dt>
                <dd className="amd-list-desc__dd">{p.total}</dd>
              </div>
              <div>
                <dt className="amd-list-desc__dt">Remote</dt>
                <dd className="amd-list-desc__dd">{p.remote}</dd>
              </div>
              <div>
                <dt className="amd-list-desc__dt">Contact</dt>
                <dd className="amd-list-desc__dd">{p.contact}</dd>
              </div>
            </dl>
          );
        }
      )}
    </div>
  ) : (
    <NoDataCard message={props.emptyStateMessage} />
  );
};
export interface IPassengerSummaryDetails {
  passengerSummaryData: IPassengerSummary[];
  emptyStateMessage: string;
}
