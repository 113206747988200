import { matchPath } from "react-router-dom";
import PageEnum from "../../../enum/PageEnum";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { EventManagement } from "../../events/EventManagement";
import { AmdButton } from "../form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../icons/arrow/arrow";
import { ArrowDirection } from "../icons/arrow/arrowDirection";
import { setIconOptions } from "@fluentui/react";

setIconOptions({
  disableWarnings: true,
});


type EventManagementCardType = {
  airport: string;
  pathname?: string | undefined;
};

export default function EventManagementCard({
  airport,
  pathname = "",
}: EventManagementCardType): React.JSX.Element {
  const homeMatch = matchPath(pathname, {
    path: "/home",
    exact: true,
    strict: false,
  });
  const isHomePage = homeMatch?.isExact;

  const goToEventManagementPage = () => (
    <AmdButton
      id="eventManagement"
      testId="eventManagement"
      type={AmdButtonType.ICON_BUTTON}
      onClick={() =>
        CommonHelper.navigateToComponent(
          `/${PageEnum.PageUrl.EventManagement}`,
          "",
          PageEnum.PageUrl.EventManagement
        )
      }
    >
      <AmdArrow direction={ArrowDirection.RIGHT} />
    </AmdButton>
  );

  return (
    <div
      className="amd-section-shadow"
      id="event-management-card"
      data-testid="event-management-card"
    >
      {isHomePage ? (
        <header
          className="amd-section-shadow__header amd-container-hspacebtwn"
          data-testid="eventManagementContainer"
        >
          <span>Event Management</span>
          {goToEventManagementPage()}
        </header>
      ) : null}
      <EventManagement airport={airport} />
    </div>
  );
}