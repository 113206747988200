import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdPin: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="pin-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.12207 1.13764C7.73257 -0.0833534 9.35986 -0.34743 10.3251 0.617854L14.382 4.67468C15.3472 5.63996 15.0832 7.26725 13.8622 7.87775L10.4037 9.60697C10.0751 9.77127 9.81827 10.0504 9.68183 10.3915L8.24585 13.9815C7.97923 14.648 7.11791 14.8248 6.61027 14.3172L4 11.7069L0.707112 14.9998H0V14.2927L3.29289 10.9998L0.682624 8.38955C0.174981 7.88191 0.351773 7.0206 1.01834 6.75397L4.60829 5.31799C4.94939 5.18155 5.22855 4.92468 5.39285 4.59609L7.12207 1.13764ZM9.61803 1.32496C9.13539 0.84232 8.32175 0.974356 8.0165 1.58486L6.28727 5.04331C6.01345 5.59095 5.54818 6.01907 4.97968 6.24646L1.38973 7.68245L7.31738 13.6101L8.75336 10.0201C8.98075 9.45165 9.40887 8.98637 9.95651 8.71255L13.415 6.98332C14.0255 6.67807 14.1575 5.86443 13.6749 5.38179L9.61803 1.32496Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

AmdPin.defaultProps = {
  color: "#005EB8",
};
