import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdGlobalFilter: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <span className={props.className}>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        data-testid={props.dataTestId}
      >
        <g>
          <path
            d="M13.5625 1.21875C13.7812 1.21875 14 1.4375 14 1.65625V2.09375C14 2.33984 13.7812 2.53125 13.5625 2.53125H7.875V3.1875C7.875 3.43359 7.65625 3.625 7.4375 3.625H6.5625C6.31641 3.625 6.125 3.43359 6.125 3.1875V2.53125H0.4375C0.191406 2.53125 0 2.33984 0 2.09375V1.65625C0 1.4375 0.191406 1.21875 0.4375 1.21875H6.125V0.5625C6.125 0.34375 6.31641 0.125 6.5625 0.125H7.4375C7.65625 0.125 7.875 0.34375 7.875 0.5625V1.21875H13.5625ZM13.5625 9.96875C13.7812 9.96875 14 10.1875 14 10.4062V10.8438C14 11.0898 13.7812 11.2812 13.5625 11.2812H4.375V11.9375C4.375 12.1836 4.15625 12.375 3.9375 12.375H3.0625C2.81641 12.375 2.625 12.1836 2.625 11.9375V11.2812H0.4375C0.191406 11.2812 0 11.0898 0 10.8438V10.4062C0 10.1875 0.191406 9.96875 0.4375 9.96875H2.625V9.3125C2.625 9.09375 2.81641 8.875 3.0625 8.875H3.9375C4.15625 8.875 4.375 9.09375 4.375 9.3125V9.96875H13.5625ZM13.5625 5.59375C13.7812 5.59375 14 5.8125 14 6.03125V6.46875C14 6.71484 13.7812 6.90625 13.5625 6.90625H11.375V7.5625C11.375 7.80859 11.1562 8 10.9375 8H10.0625C9.81641 8 9.625 7.80859 9.625 7.5625V6.90625H0.4375C0.191406 6.90625 0 6.71484 0 6.46875V6.03125C0 5.8125 0.191406 5.59375 0.4375 5.59375H9.625V4.9375C9.625 4.71875 9.81641 4.5 10.0625 4.5H10.9375C11.1562 4.5 11.375 4.71875 11.375 4.9375V5.59375H13.5625Z"
            fill={props.color}
          />
        </g>
      </svg>
    </span>
  );
};

AmdGlobalFilter.defaultProps = {
  color: "white",
  dataTestId: "global-filter-icon",
};
