import FlightsEnum from "../../enum/FlightsEnum";

const actualTimeText = "Actual Time";
export const RESOURCE_COLORS = {
  colors: ["#9BCAEB", "#009DD1", "#005EB8", "#004485"],
};

export const RESOURCE_TAB_CAROUSEL_INDEX_KEY =
  "apoc-resourceAllocation-carouselIndex";

export const RESOURCE_TABS = {
  data: [
    { text: "Gates", name: "gate", value: "gate" },
    { text: "Stand", name: "stand", value: "stand" },
    {
      text: "Baggage Belts & Chutes",
      name: "baggage",
      value: "baggage",
    },
    {
      text: "Check-in Counters",
      name: "checkInCounter",
      value: "checkInCounter",
    },
    {
      text: "Self Service Counters",
      name: "selfservice",
      value: "selfservice",
    },
  ],
};
export const RESOURCE_COMMON_FILTER_KEYS = [
  "destinationType",
  "flightNumbers",
  "origins",
  "destinations",
  "terminal",
  "scheduleTimeRange",
  "status",
  "atmCategory",
];

const isResizable = true;

export const RESOURCE_ALLOCATION_TABS = {
  baggage: [
    {
      text: "Belts Trend",
      title: "Belts",
      name: "baggageBelt",
      value: "baggageBelt",
    },
    {
      text: "Chutes Trend",
      title: "Chutes",
      name: "baggageChute",
      value: "baggageChute",
    },
  ],
  gate: [
    {
      text: "Gates Trend",
      title: "Gates",
      name: "gate",
      value: "gate",
    },
  ],
  stand: [
    {
      text: "Stand Trend",
      title: "Stand",
      name: "stand",
      value: "stand",
    },
  ],
  checkInCounter: [
    {
      text: "Check-in Counters Trend",
      title: "Check-in Counters",
      name: "checkInCounter",
      value: "counter",
      subType: "checkInCounter",
    },
  ],
};
export const RESOURCE_COMMON_COLUMNS = [
  {
    name: "Type",
    fieldName: "flightType",
    minWidth: 20,
    maxWidth: 30,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
  },
  {
    name: "Start Time",
    fieldName: "resourceAllocationStart",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
  {
    name: "End Time",
    fieldName: "resourceAllocationEnd",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
  {
    name: "Schedule",
    fieldName: "schedule",
    minWidth: 40,
    maxWidth: 50,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
  },
  {
    name: "Schedule",
    fieldName: "sibt",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
  },
  {
    name: "Schedule",
    fieldName: "sobt",
    minWidth: 60,
    maxWidth: 70,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
  },
  {
    name: "Estimated Time",
    fieldName: "estimated",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
  },
  {
    name: "Estimated Time of Arrival",
    fieldName: "eibt",
    minWidth: 60,
    maxWidth: 190,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
  },
  {
    name: "Estimated Time of Departure",
    fieldName: "eobt",
    minWidth: 60,
    maxWidth: 200,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
  },
  {
    name: actualTimeText,
    fieldName: "actual",
    minWidth: 40,
    maxWidth: 70,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
    viewType: [
      FlightsEnum.ViewType.Baggage.toString(),
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
    ],
  },
  {
    name: actualTimeText,
    fieldName: "aibt",
    minWidth: 60,
    maxWidth: 150,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
  },

  {
    name: actualTimeText,
    fieldName: "eobt",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
  },
  {
    name: "Origin",
    fieldName: "origin",
    minWidth: 40,
    maxWidth: 50,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
  {
    name: "Destination",
    fieldName: "destination",
    minWidth: 60,
    maxWidth: 70,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
  {
    name: "Terminal",
    fieldName: "terminal",
    minWidth: 50,
    maxWidth: 70,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
  {
    name: "Airlines",
    fieldName: "airline",
    minWidth: 40,
    maxWidth: 60,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
  {
    name: "Flight",
    fieldName: "flightNumber",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
  {
    name: "Status",
    fieldName: "status",
    minWidth: 90,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
];

export const GATES_COLUMNS = [
  {
    name: "Gate",
    fieldName: "gate",
    minWidth: 20,
    maxWidth: 30,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
  {
    name: "Pax",
    fieldName: "paxCount",
    minWidth: 20,
    maxWidth: 30,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
];
export const STAND_COLUMNS = [
  {
    name: "Stand",
    fieldName: "stand",
    minWidth: 20,
    maxWidth: 30,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
];
export const BAGGAGE_COLUMNS = [
  {
    name: "Belts",
    fieldName: "baggageBelt",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
  },
  {
    name: "Chutes",
    fieldName: "baggageChute",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [FlightsEnum.ViewType.Baggage.toString()],
  },
];
export const CHECKIN_COLUMNS = [
  {
    name: "Counter Group",
    fieldName: "checkInCounterGroup",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
  {
    name: "Counter Name",
    fieldName: "checkInCounter",
    minWidth: 60,
    maxWidth: 90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
  },
];
export const RESOURCE_SPECIFIC_COLUMNS = {
  gate: GATES_COLUMNS,
  stand: STAND_COLUMNS,
  baggage: BAGGAGE_COLUMNS,
  checkInCounter: CHECKIN_COLUMNS,
};
export const GATES_FILTERS = [
  {
    category: "Gates",
    key: "gate",
    hide: false,
    filterItems: [
      {
        name: "gate",
        key: "gate",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
];
export const STAND_FILTERS = [
  {
    category: "Stand",
    key: "stand",
    hide: false,
    filterItems: [
      {
        name: "stand",
        key: "stand",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
];
export const BAGGAGE_FILTERS = [
  {
    category: "Baggage Belts",
    key: "baggageBelt",
    hide: false,
    filterItems: [
      {
        name: "baggageBelt",
        key: "baggageBelt",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
  {
    category: "Baggage Chutes",
    key: "baggageChute",
    hide: false,
    filterItems: [
      {
        name: "baggageChute",
        key: "baggageChute",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
];
export const CHECKIN_FILTERS = [
  {
    category: "Counter Group",
    key: "checkInCounterGroup",
    hide: false,
    filterItems: [
      {
        name: "checkInCounterGroup",
        key: "checkInCounterGroup",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
  {
    category: "Counter Name",
    key: "checkInCounter",
    hide: false,
    filterItems: [
      {
        name: "checkInCounter",
        key: "checkInCounter",
        value: "",
        type: "text",
        selected: false,
      },
    ],
  },
];
export const RESOURCE_SPECIFIC_FILTERS = {
  gate: GATES_FILTERS,
  stand: STAND_FILTERS,
  baggage: BAGGAGE_FILTERS,
  checkInCounter: CHECKIN_FILTERS,
};
