import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ILegend } from "@fluentui/react-charting";
import { useBoolean } from "@fluentui/react-hooks";
import React from "react";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import {
  setWeatherDisruptionCards,
  setWeatherDisruptionTableKPI,
} from "../../../store/weather-disruption/WeatherDisruptionSlice";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdTeams } from "../../common/icons/teams/Teams";
import { WeatherDisruptionContainer } from "./WeatherDisruptionContainer";

import { reformatTablePopUpDataforGraph } from "../../../utils/events/reformatTablePopUpDataforGraph";
import { defaultGetWeatherDisruptionCards } from "./defaultGetWeatherDisruptionCards";
import { SharePopup } from "./SharePopup";
import {
  FetchWeatherDisruptionContainersApiResponse,
  ICrisisManagementProps,
} from "./types";

export const CrisisDashboard: React.FunctionComponent<
  ICrisisManagementProps
> = ({
  eventId,
  getWeatherDisruptionCards = defaultGetWeatherDisruptionCards,
}: ICrisisManagementProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const eventManagementEventId =
    EventManagementHelper.getEventIdFromCrisisboardURL(eventId);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const dispatch = useAppDispatch();
  const airportCode = useAppSelector((state) => getAirportCode(state));

  const weatherDisruptionState = useAppSelector((state: any) =>
    getWeatherDisruptionState(state)
  );

  const {
    selectedDisruptionKPI,
    selectedGraphData,
    weatherDisruptionCards,
  } = weatherDisruptionState;

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && airportCode) {
      getWeatherDisruptionCards(msalInstance, eventId, airportCode).then(
        (response: FetchWeatherDisruptionContainersApiResponse[]) => {
          if (response?.length) {
            dispatch(
              setWeatherDisruptionCards({ weatherDisruptionCards: response })
            );
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [airportCode, eventId, msalInstance]);

  const showSharePopup = () => {
    showModal();
  };

  const getFormattedGraphData = () => {
    const updatedGraphData = reformatTablePopUpDataforGraph(
      selectedGraphData,
      selectedDisruptionKPI
    );
    if (updatedGraphData) {
      dispatch(
        setWeatherDisruptionTableKPI({
          selectedDisruptionKPI: updatedGraphData,
        })
      );
    }
  };

  return (
    <div data-testid="crisis-Container" className="mt-2 mb-1 br-b-1">
      <WeatherDisruptionContainer
        getFormattedGraphData={getFormattedGraphData}
        airport={airportCode}
        eventId={eventManagementEventId}
        weatherDisruptionCards={weatherDisruptionCards}
      />
      <div className="w-100 amd-container-vcenter">
        <div className="w-80 amd-container-vcenter"></div>
        <div className="float-r amd-container-vcenter w-20 jc-end">
          <AmdButton
            id="share"
            data-testid="share"
            testId="share"
            text="Share"
            onClick={showSharePopup}
            type={AmdButtonType.DEFAULT}
            prefixImageIconBeforeText={true}
            imageIcon={<AmdTeams className="mt-1 mr-1" />}
            className="mb-1"
          />
        </div>
      </div>
      <SharePopup
        hideDialog={!isModalOpen}
        data-testid="share-popup"
        onModalPopupClose={hideModal}
        eventId={eventManagementEventId}
        airport={airportCode}
      ></SharePopup>
    </div>
  );
};

export interface IWeatherDisruptionComponentState {
  width: number;
  height: number;
  allowMultipleShapes: boolean;
  selectedTouchPoint: string;
  selectedTouchPointFilter: string;
  selectedStartTime: string;
  weatherDisruptionChartDataArray: any[];
  weatherDisruptionChartLegendArray: ILegend[][];
}
