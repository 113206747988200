import { PublicClientApplication } from "@azure/msal-browser";
import { mergeStyles } from "@fluentui/react";
import {
  IChartProps,
  ILegend,
  ILineChartDataPoint,
  ILineChartPoints,
} from "@fluentui/react-charting";
import moment from "moment-timezone";
import { DataType } from "../../components/common/DataType";
import HOPEnum from "../../enum/HOPEnum";
import ResourceEnum from "../../enum/ResourceEnum";
import IPassengerGraph from "../../models/pax/IPassengerGraph";
import IPassengerSummary from "../../models/pax/IPassengerSummary";
import PassengerService from "../../services/pax/passenger.service";
import { PaxDataResultType } from "../../store/pax-flow/contracts/contracts";
import { APIHelper } from "../api/APIHelper";
import { CommonHelper } from "../common/CommonHelper";
import { PAX_CHART_SERIES } from "../common/Constants";
import DateHelper from "../common/DateHelper";
import { FlightListHelper } from "../flights/FlightListHelper";
import FlightScheduleHelper from "../flights/FlightScheduleHelper";
import { RESOURCE_COLORS } from "../resource/ResourceConstants";
import {
  PAX_TOUCHPOINTS_ARRAY,
  PAX_TOUCHPOINTS_REFRESH_INTERVAL,
} from "./PAXConstants";
import { getMappedTableData } from "./getMappedTableData";

type ILegendExt = { type?: string; key: string };
export type ILegendExtended = ILegend & ILegendExt;
export type ILineChartPointsExtended = ILineChartPoints & ILegendExt;

export abstract class PassengerHelper {
  public static async getPassengerSummary(
    msalInstance: PublicClientApplication,
    airportCode: string
  ): Promise<any> {
    const passengerService = new PassengerService();
    const passengerSummaryMethod = (accessToken: string) => {
      return passengerService.getPassengerSummary(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, passengerSummaryMethod);
  }

  public static async getPassengerGraphData(
    msalInstance: PublicClientApplication,
    airport: string,
    flightType: string
  ): Promise<any> {
    const passengerService = new PassengerService();
    const flightListState = FlightScheduleHelper.getDefaultOtpFlightListState(
      ResourceEnum.ResourceType.Passenger,
      airport
    );
    const updatedFlightListState = { ...flightListState, flightType };
    const flightListServicePayload =
      FlightListHelper.getFlightListServicePayload(updatedFlightListState);
    const passengerGraphMethod = (accessToken: string) => {
      return passengerService.getPassengerGraphData(
        accessToken,
        airport,
        flightListServicePayload
      );
    };

    return APIHelper.CallAPI(msalInstance, null, passengerGraphMethod);
  }

  public static async getTouchPointGraphData(
    msalInstance: PublicClientApplication,
    touchPointName: string,
    timeInterval: string
  ): Promise<any> {
    const passengerService = new PassengerService();
    const touchPointGraphDataMethod = async (
      accessToken: string
    ): Promise<PaxDataResultType | undefined> => {
      const response = await passengerService.getTouchPointGraphData(
        accessToken,
        touchPointName,
        timeInterval
      );
      return response;
    };

    return APIHelper.CallAPI(msalInstance, null, touchPointGraphDataMethod);
  }

  public static async getTouchPointTableData(
    msalInstance: PublicClientApplication,
    touchPointName: string,
    timeInterval: string
  ): Promise<any> {
    const passengerService = new PassengerService();
    const touchPointTableDataMethod = (accessToken: string) => {
      return passengerService.getTouchPointTableData(
        accessToken,
        touchPointName,
        timeInterval
      );
    };

    return APIHelper.CallAPI(msalInstance, null, touchPointTableDataMethod);
  }

  public static getTotalPassengersCount(
    data: IPassengerSummary[],
    key: string
  ) {
    return data.reduce((a, b) => a + (b as { [k in string]: any })[key], 0);
  }

  public static getChartData(
    passengerSummaryData: IPassengerSummary[],
    key: string,
    isTotal = false
  ) {
    const chartData: any[] = [];
    if (isTotal) {
      const total = this.getTotalPassengersCount(passengerSummaryData, key);
      passengerSummaryData?.forEach((item: any, index: number) => {
        const obj = {
          resourceName: CommonHelper.toTitleCase(key),
          resourceCount: item.total,
          resourceTitle: CommonHelper.toTitleCase(item.type),
          resourceColor: RESOURCE_COLORS.colors[index],
          resourceTotal: total,
        };
        chartData.push(obj);
      });
    } else {
      const arr = ["contact", "remote"];
      const data = passengerSummaryData?.find((f) => f.type === key);
      let total = 0;
      if (data) {
        total = data.remote + data.contact;
        arr?.forEach((item: string, index: number) => {
          const obj = {
            resourceName: CommonHelper.toTitleCase(key),
            resourceCount: (data as { [k in string]: any })[item],
            resourceTitle: CommonHelper.toTitleCase(item),
            resourceColor: RESOURCE_COLORS.colors[index],
            resourceTotal: total,
          };
          chartData.push(obj);
        });
      }
    }
    return chartData;
  }

  public static getGroupedBarData(response: IPassengerGraph[]) {
    let graphData: any[] = [];
    graphData = response?.map((p) => ({
      name: moment(new Date(p.time)).format("HH:mm"),
      series: this.getSeriesDetails(p, PAX_CHART_SERIES),
    }));
    return graphData;
  }

  public static getSeriesDetails(pax: IPassengerGraph, chartSeries: any[]) {
    let series: any[] = [];
    series = chartSeries?.map((c, index) => ({
      key: `series${index}`,
      data: (pax as { [k in string]: any })[c.key],
      xAxisCalloutData: c.text,
      color: RESOURCE_COLORS.colors[index],
      legend: c.text,
    }));
    return series;
  }

  public static async getPAXGraphAndTableData(
    msalInstance: PublicClientApplication,
    selectedRefreshInterval: string
  ) {
    const graphDataPromiseArray: Promise<any>[] = [];
    const tableDataPromiseArray: Promise<any>[] = [];

    PAX_TOUCHPOINTS_ARRAY.forEach((touchPointName: string) => {
      graphDataPromiseArray.push(PassengerHelper.getTouchPointGraphData(
        msalInstance,
        touchPointName,
        selectedRefreshInterval
      ));
      tableDataPromiseArray.push(
        PassengerHelper.getTouchPointTableData(
          msalInstance,
          touchPointName,
          selectedRefreshInterval
        )
      );
    });
    const graphDataResponse = await Promise.all(graphDataPromiseArray);
    const tableDataResponse = await Promise.all(tableDataPromiseArray);
    return [graphDataResponse, tableDataResponse];
  }

  public static getPAXFlowDefaultState() {
    return {
      width: 1200,
      height: 330,
      allowMultipleShapes: false,
      selectedTouchPoint: "",
      selectedTouchPointFilter: "",
      selectedStartTime: DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
      selectedEndTime: PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
        DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
        PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
        true
      ),
      selectedRefreshInterval: PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
      touchPointChartDataArray: [],
      prevTouchPointChartDataArray: [],
      touchPointTableDataArray: [],
      touchPointTableColumnsArray: [],
      touchPointChartLegendArray: [],
    };
  }

  public static getUpdatedStateBasedOnTimePickerChange(
    stateObj: any,
    pickerType: string,
    selectedTime: string
  ) {
    if (pickerType === "start") {
      stateObj.selectedStartTime = selectedTime;
    } else if (pickerType === "end") {
      stateObj.selectedEndTime = selectedTime;
    }
    const updatedTime = PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
      selectedTime,
      stateObj.selectedRefreshInterval,
      pickerType === "start"
    );

    stateObj[pickerType === "start" ? "selectedEndTime" : "selectedStartTime"] =
      updatedTime;
    return stateObj;
  }

  public static getUpdatedStateBasedOnGraphTableData(
    paxGraphTableResponse: any,
    stateObj: any,
    tableItemStyle: string
  ) {
    const PAX_TOUCHPOINTS_GRAPH_INFO = [
      "airportEntry",
      "checkIn",
      "security",
      "boarding",
    ];

    const graphData = paxGraphTableResponse[0];
    const tableData = paxGraphTableResponse[1];
    const mappedData = tableData?.map((item: any) => item?.metrics);

    const dataMetrics = getMappedTableData(mappedData);

    const touchPointChartDataArray: any[] = [];
    const touchPointTableColumnsArray: any[] = [];
    const touchPointChartLegendArray: any[] = [];

    graphData.forEach((touchPointChartData: any, index: any) => {
      const chartData = PassengerHelper.getPAXFlowChartData(
        PAX_TOUCHPOINTS_GRAPH_INFO[index],
        touchPointChartData.data
      );
      touchPointChartDataArray.push(chartData);
      touchPointChartLegendArray.push(
        PassengerHelper.getPAXFlowGraphLegends(chartData)
      );
    });

    stateObj.touchPointChartDataArray = touchPointChartDataArray;
    stateObj.touchPointChartLegendArray = touchPointChartLegendArray;

    tableData.map((touchPointTableData: any) => {
      const timeSeries = touchPointTableData.metrics[0].data
        .map((item: any) => {
          return Object.keys(item);
        })
        .flat();
      const timeSeriesObj = timeSeries.reduce(
        (a: any, key: any) => Object.assign(a, { [key]: key }),
        {}
      );
      const { key, factors, threshold } = touchPointTableData.metrics[0];
      const pickedColumns = {
        key,
        factors,
        threshold,
        ...timeSeriesObj,
      };
      touchPointTableColumnsArray.push(
        PassengerHelper.getPAXFlowTableColumns(
          Object.keys(pickedColumns),
          tableItemStyle
        )
      );
    });

    stateObj.touchPointTableDataArray = dataMetrics;
    stateObj.touchPointTableColumnsArray = touchPointTableColumnsArray;
    return stateObj;
  }

  public static getUpdatedPAXFlowTimeBasedOnInterval(
    timeInHHMMFormat: string,
    refreshInterval: string,
    updateEndTime = true
  ) {
    const dateTime = moment(
      DateHelper.getDateTimeFromHHMMFormat(timeInHHMMFormat)
    );
    if (updateEndTime) {
      const date = dateTime
        .clone()
        .add(parseInt(refreshInterval) * 12, "minutes"); // multiply by 12 as we can fit in 12 points in graph

      return date.isAfter(dateTime.endOf("day"))
        ? "23:59"
        : DateHelper.getFormattedDateTime(date.toDate(), "HH:mm");
    } else {
      const date = dateTime
        .clone()
        .subtract(parseInt(refreshInterval) * 12, "minutes");

      return date.isBefore(dateTime.startOf("day"))
        ? "00:00"
        : DateHelper.getFormattedDateTime(date.toDate(), "HH:mm");
    }
  }

  public static getBorderStyles(filterStatus: string, isDotted: boolean) {
    return mergeStyles({
      border: `1px ${
        isDotted ? "dashed" : "solid"
      } ${PassengerHelper.getStatusColor(filterStatus)}`,
    });
  }

  public static getStatusColor(status: string) {
    if (status === HOPEnum.HOPDataStatus.Poor) {
      return "#c60000";
    } else if (status === HOPEnum.HOPDataStatus.Moderate) {
      return "#f7a827";
    } else {
      return "#008540";
    }
  }

  public static getPAXFlowGraphLegends = (graphData: any) => {
    const legends: ILegendExtended[] = [];
    graphData?.lineChartData?.forEach((data: any, index: number) => {
      legends.push({
        key: `${data.key}${index}`,
        title: data.legend,
        type: data?.type,
        color: data.color,
        shape: data.showDottedLine ? "dottedLine" : "default",
      });
    });
    return legends;
  };

  public static getPAXFlowTableColumns = (
    columnsArray: string[],
    tableItemStyle: string
  ) => {
    const columns: any[] = [];
    columnsArray.forEach((columnItem: string, index: number) => {
      if (index === 0) {
        columns.push({
          key: columnItem,
          name: columnItem,
          fieldName: columnItem,
          minWidth: 190,
          maxWidth: 190,
          dataType: DataType.String,
          isRowHeader: true,
        });
      } else {
        const regex = /^([01]\d|2[0-3]):[0-5]\d-([01]\d|2[0-3]):[0-5]\d$/;
        const matchedGroup = columnItem?.match(regex);
        const updatedFieldName = matchedGroup?.length
          ? "timeSeries"
          : columnItem;
        columns.push({
          key: columnItem,
          name: columnItem,
          fieldName: updatedFieldName,
          minWidth: 20,
          maxWidth: 20,
          dataType: DataType.String,
          isRowHeader: true,
          onRender: (item: any) => (
            <div className={tableItemStyle}>{item[columnItem]}</div>
          ),
        });
      }
    });

    return columns;
  };

  public static getPAXFlowChartData(chartTitle: string, graphData: any) {
    const chartData: IChartProps = {
      chartTitle: chartTitle,
    };
    const lineChartPoints: ILineChartPointsExtended[] = [];
    graphData.forEach((data: any) => {
      lineChartPoints.push(PassengerHelper.getPAXFlowLineChartPoints(data));
    });
    chartData.lineChartData = lineChartPoints;
    return chartData;
  }

  public static getPAXFlowLineChartPoints(data: any) {
    const lineChartPoints: ILineChartPointsExtended = {
      key: data.key,
      legend: data.datasetName,
      type: data?.type,
      color: data.color,
      data: PassengerHelper.getPAXFlowLineChartPointsData(data.points),
    };

    if (data.showDottedLine) {
      lineChartPoints.lineOptions = {
        strokeDasharray: "5",
        strokeLinecap: "butt",
        strokeWidth: "2",
        lineBorderWidth: "4",
      };
    }
    return lineChartPoints;
  }

  public static getPAXFlowLineChartPointsData(dataPoints: any) {
    const lineChartPointsData: ILineChartDataPoint[] = [];
    dataPoints.forEach((dataPoint: any) => {
      lineChartPointsData.push({
        x: new Date(dataPoint.x),
        y: dataPoint.y,
      });
    });
    return lineChartPointsData;
  }

  public static enableTouchPointFilter(
    selectedTouchPoint: string,
    currentTouchPointKey: string,
    currentTouchPointFilter: string,
    selectedTouchPointFilterKey: string
  ): boolean {
    return selectedTouchPoint !== ""
      ? selectedTouchPoint === currentTouchPointKey &&
          selectedTouchPointFilterKey === currentTouchPointFilter
      : true;
  }
}
