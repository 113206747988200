import { mergeStyles } from "@fluentui/react";
import React from "react";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";
import { getTableStyles } from "./AmdTableStyles";

interface IAmdTableColumn {
  name: string;
  columnLabel: string | null;
  width?: string;
}

interface IAmdTableRow {
  [k: string]: {
    content?: React.ReactElement;
    text?: string;
  };
}

interface IAmdTableProps {
  columns: IAmdTableColumn[];
  data: IAmdTableRow[] | any[];
  className?: string;
  tableType?: "stripped_columns" | "stripped_rows";
  tableWidth?: string;
  tableLayout?: "fixed" | "auto";
}

export const AmdTable: React.FunctionComponent<IAmdTableProps> = (
  props: IAmdTableProps
) => {
  const tableStyles = getTableStyles(
    props.tableType,
    props.tableWidth,
    props.tableLayout
  );
  const renderColumnHeaders =
    props.columns.filter((x) => x.columnLabel !== "").length > 0;
  const defaultColWidth = props.tableLayout === "fixed" ? "50px" : "auto";
  return (
    <table className={`${tableStyles.table} ${props.className}`}>
      <colgroup>
        {props.columns.map((x) => (
          <col
            key={`${x.name}_col`}
            width={x.width ? x.width : defaultColWidth}
          />
        ))}
      </colgroup>
      {renderColumnHeaders && (
        <thead>
          <tr>
            {props.columns.map((x) => {
              const thStyle = mergeStyles({
                width: x.width ? x.width : defaultColWidth,
                maxWidth: x.width ? x.width : defaultColWidth,
              });
              return (
                x.columnLabel !== "" && (
                  <th
                    key={CommonHelper.getGuid()}
                    className={`${tableStyles.headerCell} ${thStyle}`}
                  >
                    {x.columnLabel}
                  </th>
                )
              );
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {props.data.map((row) => {
          return (
            <tr className={tableStyles.row} key={CommonHelper.getGuid()}>
              {props.columns.map((col) => {
                const tdStyle = mergeStyles({
                  width: col.width ? col.width : defaultColWidth,
                  maxWidth: col.width ? col.width : defaultColWidth,
                });
                if (row[col.name].content) {
                  return (
                    <td
                      key={CommonHelper.getGuid()}
                      className={`${tableStyles.cell} ${tdStyle}`}
                    >
                      {row[col.name].content}
                    </td>
                  );
                } else if (row[col.name].text) {
                  return (
                    <td
                      key={CommonHelper.getGuid()}
                      className={`${tableStyles.cell} ${tdStyle}`}
                    >
                      {row[col.name].text}
                    </td>
                  );
                }
                return (
                  <td
                    key={CommonHelper.getGuid()}
                    className={`${tableStyles.cell} ${tdStyle}`}
                  >
                    {row[col.name]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

AmdTable.defaultProps = {
  tableType: "stripped_columns",
  tableWidth: "auto",
  tableLayout: "auto",
};
