import { ITag } from "@fluentui/react/lib/Pickers";
import AIRLINES_MOCK from "../../mock/global-filter/airlines-data.json";
import { operatorOptions } from "./TagPickerConstants";

export const getAirlines = () => AIRLINES_MOCK.data.airlines;

export const getTextFromItem = (item: ITag) => item.name;

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const filterSuggestedTags = (
  filter: string,
  selectedItems?: ITag[]
): ITag[] | PromiseLike<ITag[]> => {
  const airlines = getAirlines();
  return filter
    ? airlines.filter(
        (tag) =>
          tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 &&
          !listContainsTagList(tag, selectedItems)
      )
    : [];
};

export const getOpearatorOptions = (inputType: string) => {
  switch (inputType) {
    case "number":
      return [...operatorOptions.number, ...operatorOptions.common];
    case "string":
      return operatorOptions.common;
    default:
      return operatorOptions.common;
  }
};
