import { DefaultPalette, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";

type CardProps = {
  children: React.ReactElement;
  header?: React.ReactElement;
  width: number | string;
  height: number;
  className?: string;
  dataTestId?: string;
  id?: string;
  onClick?: any;
  headerType?: "default_header" | "custom_header";
  bodyType?: "default_body" | "custom_body";
  footerType?: "none" | "default_footer";
  headerAlignment?: "space-between" | "center";
  footer?: React.ReactElement;
};

const Card: React.FunctionComponent<CardProps> = (props: CardProps) => {
  const cardStyles = mergeStyleSets({
    root: {
      background: DefaultPalette.white,
      width: props.width,
      height: props.height,
      justifyContent: "center",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      cursor: "pointer",
      padding: props.headerType === "default_header" ? 13 : 0,
    },
    header: {
      display: "flex",
      justifyContent: props.headerAlignment,
      paddingBottom: 10,
      selectors: {
        "& span": {
          fontSize: 14,
          lineHeight: 18,
          color: "#333333",
          fontWeight: 600,
        },
      },
    },
    body: {
      height: 54,
      paddingTop: 13,
      paddingBottom: 13,
    },
    footer: {
      height: 32,
    },
  });

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={`${cardStyles.root} ${props.className}`}
      data-testid={props.dataTestId}
      onClick={() => handleClick()}
      key={CommonHelper.getGuid()}
    >
      {props.headerType === "custom_header" && <>{props.children}</>}
      {props.headerType === "default_header" && (
        <div className={cardStyles.header}>{props.header}</div>
      )}
      {props.bodyType === "custom_body" && <div>{props.children}</div>}
      {props.bodyType === "default_body" && (
        <div className={cardStyles.body}>{props.children}</div>
      )}
      {props.footerType === "default_footer" && (
        <div className={cardStyles.footer}>{props.footer}</div>
      )}
    </div>
  );
};

Card.defaultProps = {
  width: 386,
  height: 170,
  className: "",
  dataTestId: "",
  headerType: "custom_header",
  bodyType: "custom_body",
  footerType: "none",
  headerAlignment: "space-between",
};

export default Card;
