import {
  DefaultButton,
  DefaultPalette,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { Divider, Image } from "@fluentui/react-northstar";
import React from "react";
import IFilters, { IFilterItem } from "../../../models/filters/IFilters";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { FilterItem } from "../filter-item/FilterItem";
import CrossIcon from "../images/cross-icon.svg";

export const FilterContainer: React.FunctionComponent<IFilterProps> = ({
  onApplyFilter,
  onClearFilter,
  onDismissFilter,
  selectedFilters,
  onUpdateFilter,
  airport,
}) => {
  const [isDisabled, setIsDisabled] = React.useState<any>({
    duration: true,
    start: true,
    end: true,
  });

  const onFilterSelection = (
    category?: string,
    key?: string,
    type?: string,
    selectedDuration?: string,
    sTime?: Date,
    eTime?: Date
  ) => {
    const updatedItems = FlightScheduleHelper.getUpdatedFilterItems(
      airport,
      selectedFilters,
      category,
      key,
      type,
      selectedDuration,
      sTime,
      eTime
    );
    if (onUpdateFilter) {
      onUpdateFilter(updatedItems);
    }
  };

  const applyFilters = () => {
    if (onApplyFilter !== undefined) {
      onApplyFilter();
    }
  };

  const dismissFilters = () => {
    if (onDismissFilter !== undefined) {
      onDismissFilter();
    }
  };

  const clearFilters = () => {
    if (onClearFilter !== undefined) {
      onClearFilter();
    }
  };

  const _onFilterItemClick = (item: IFilterItem) => {
    if (item.key === "customTimeRange" || item.key === "customDuration") {
      setIsDisabled(FlightScheduleHelper.setDisabled(item, isDisabled));
    } else if (item.type === "tagpicker") {
      onFilterSelection(item.name, item.key, item.type, item.selectedValue);
    } else {
      onFilterSelection(item.name, item.key, item.type);
    }
  };

  const _onCustomInputClick = (
    selectedTarget: HTMLInputElement,
    item: IFilterItem
  ) => {
    const selectedValue =
      selectedTarget.value !== undefined
        ? selectedTarget.value
        : selectedTarget.innerText;
    onFilterSelection(item.name, item.key, item.type, selectedValue);
  };
  return (
    <>
      {selectedFilters && (
        <Stack
          enableScopedSelectors
          styles={filterItemStackStyles}
          tokens={stackTokens}
          className="filterContainer"
        >
          <Stack.Item styles={stackItemStyles}>
            <span className="spanTitle">Filters</span>
            <span
              className="spnDismiss float-right"
              onClick={dismissFilters}
              style={{ cursor: "pointer" }}
            >
              <Image src={CrossIcon} />
            </span>
          </Stack.Item>
          <Divider className="dividerStyle" />
          {selectedFilters?.map((filter: IFilters) => (
            <FilterItem
              key={filter.key}
              _onFilterItemClick={_onFilterItemClick}
              _onCustomInputClick={_onCustomInputClick}
              filter={filter}
              onFilterSelection={onFilterSelection}
              isDisabled={isDisabled}
              airport={airport}
            />
          ))}
          <Stack.Item styles={filterActionStyles}>
            <Stack horizontal tokens={filterTokens} className="filterAction">
              <DefaultButton
                text="Clear All"
                className="defaultBtn"
                onClick={clearFilters}
              />
              <PrimaryButton
                text="Apply"
                className="primaryBtn"
                onClick={applyFilters}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};
interface IFilterProps {
  onApplyFilter?: () => void;
  onClearFilter?: () => void;
  onDismissFilter?: () => void;
  selectedFilters?: IFilters[];
  onUpdateFilter?: any;
  airport: string;
}

const filterItemStackStyles: IStackStyles = {
  root: {
    background: "#005EB8",
    boxShadow: "-5px 0px 25px rgba(0, 0, 0, 0.25)",
    width: "300px",
    height: "820px",
  },
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: "32px",
  },
};

const filterActionStyles: IStackItemStyles = {
  root: {
    background: DefaultPalette.white,
  },
};

const stackTokens: IStackTokens = { childrenGap: 5 };
const filterTokens = { childrenGap: 10 };
